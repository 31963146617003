import React from 'react'
import './CCPAPolicy.scss'
import { Container, Row, Col } from 'react-bootstrap'
import Footer from '../../components/footer/Footer';

const CCPAPolicy = () => {
    return (
        <>
            <article className="policy-container">
                <Container>
                    <Row>
                        <Col>
                            <h1>WisePrezzie CCPA Policy</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>This CCPA Policy is incorporated by reference into the WisePrezzie Privacy Policy, and applies only to California consumers and the Personal Information of California consumers. If you are a California resident, the processing of certain personal data about you may be subject to the California Consumer Privacy Act (“CCPA”) and other applicable California state privacy laws. If you have any questions about whether this CCPA Policy applies to you, please contact us at privacy@wiseprezzie.com. Any capitalized terms not defined in this CCPA Policy have the same meaning given to them in our Privacy Policy and/or the CCPA.</p>
                            <h2>No Sale of Personal Information</h2>
                            <p>Under the CCPA, a “sale” means providing to a third party personal information for valuable consideration. At this time, we don’t believe any of our data practices constitute a “sale” under the CCPA.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h2>Individual Rights</h2>
                            <p>The CCPA provides California consumers with several individual rights with respect to Personal Information. Note that these rights apply to individual consumers, not to companies. This section describes those rights in detail and provides information on how to exercise those rights.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h2>Exercising Your Rights</h2>
                            <p>To exercise any of the rights described in this section, please contact us at privacy@wiseprezzie.com with (i) a complete description of your request, including the specific right(s) you wish to exercise and (ii) sufficient information about you so we can confirm that your request is a verifiable customer request, including at a minimum your name and email address. Once we have received your verifiable consumer request, we will respond consistent with applicable law. Please note that you may also designate an authorized agent to make a request on your behalf. In order for us to process a request from your authorized agent, we must (i) confirm that the agent is a natural person or business entity registered with the Secretary of State that you have authorized to act on your behalf, (ii) receive from you a copy of the written authorization that provides the authorized agent to act on your behalf, and (iii) verify your identity by asking you to provide us sufficient information in order to do so.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h2>Access and Data Portability Rights</h2>
                            <p>You have a right to request information about our collection, use, and disclosure of your personal information over the prior 12 months, and ask that we provide you with the following information:</p>
                            <ol>
                                <li>Categories of and specific pieces of personal information we have collected about you.</li>
                                <li>Categories of sources from which we collect personal information.</li>
                                <li>Purposes for collecting, using, or selling personal information.</li>
                                <li>Categories of third parties with which we share personal information.</li>
                                <li>Categories of personal information disclosed about you for a business purpose.</li>
                                <li>If applicable, categories of personal information sold about you and the categories of third parties to which the personal information was sold, by category or categories of personal information for each third party to which the personal information was sold.</li>
                            </ol>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h2>Deletion Rights</h2>
                            <p>You have the right to request that we delete Personal Information about you that we have collected, subject to certain exceptions.
                                <br />
                                You may also access, edit, or delete certain of your Personal Information through your account settings. Please review our Privacy Policy, in the section titled “What Personal Information can I access?”, for more details.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h2>Non-Discrimination</h2>
                            <p>You have the right not to receive discriminatory treatment for the exercise of your rights under the CCPA, subject to certain limitations. Please note that we reserve the right to offer you certain financial incentives as permitted by the CCPA that may result in different prices, rates, or quality levels. Any CCPA-permitted financial incentive we offer will reasonably relate to your personal information's value and contain written terms that describe the program's material aspects. Participation in a financial incentive program requires your prior opt-in consent, which you may revoke at any time by contacting us.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h2>Personal Data Collected</h2>
                            <p>The list below describes the category of Personal Information we collect by reference to the categories specified by the CCPA:</p>
                            <ul>
                                <li><strong>Category:</strong> Identifiers</li>
                                <li><strong>We collect:</strong> Your name, IP address, and email address</li>
                                <li><strong>Source(s):</strong> You</li>
                                <li><strong>Category:</strong> Customer Record Information</li>
                                <li><strong>We collect:</strong> Your name</li>
                                <li><strong>Source(s):</strong> You</li>
                                <li><strong>Category:</strong> Third-Party Information Record Information</li>
                                <li><strong>We collect:</strong> Name, email address, characteristics, event-related and other optional data provided by you while using the services on the Website</li>
                                <li><strong>Source(s):</strong> You</li>
                                <li><strong>Category:</strong> Commercial information</li>
                                <li><strong>We collect:</strong> Records of products/services purchased by you on the Website</li>
                                <li><strong>Source(s):</strong> You</li>
                                <li><strong>Category:</strong> Internet or other network activity</li>
                                <li><strong>We collect:</strong> Browsing history on our Website</li>
                                <li><strong>Source(s):</strong> You; Automatic collection (see our Privacy Policy for more information on tracking technologies we use for automatic data collection)</li>
                                <li><strong>Category:</strong> Sensory Data</li>
                                <li><strong>We collect:</strong> Audio files provided or recorded by users using our products and services</li>
                                <li><strong>Source(s):</strong> You</li>
                            </ul>
                            <p>We will not collect additional categories of personal information or use the personal information we collected for materially different, unrelated, or incompatible purposes without providing you notice.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h2>Use of Personal Information</h2>
                            <p>In the last 12 months, we have used or disclosed certain Personal Information we collected for the following business purposes:</p>
                            <ul>
                                <li><strong>Business Purpose:</strong> To provide, support, and develop our website, products, and services</li>
                                <li><strong>Categories of Personal Information:</strong> Identifiers, Customer Record Information, Sensory Data, Internet of other network activity</li>
                                <li><strong>Business Purpose:</strong> To create, maintain, customize, and secure your account with us</li>
                                <li><strong>Categories of Personal Information:</strong> Identifiers, Customer Record Information</li>
                                <li><strong>Business Purpose:</strong> To process your requests, purchases, transactions, and payments and prevent transactional fraud</li>
                                <li><strong>Categories of Personal Information:</strong> Commercial Information </li>
                                <li><strong>Business Purpose:</strong> To directly respond to your requests or inquiries, including to investigate and address your concerns and monitor and improve our responses, or to otherwise meet the reason for which you provided the information.</li>
                                <li><strong>Categories of Personal Information:</strong> Identifiers, Customer Record Information, Commercial Information, Sensory Data, Internet or other network activity</li>
                                <li><strong>Business Purpose:</strong> To help maintain the safety, security, and integrity of our website, products and services, databases and other technology assets, and business</li>
                                <li><strong>Categories of Personal Information:</strong> Identifiers</li>
                            </ul>
                            <p>We may further disclose each category of Personal Information to our affiliates, to our professional advisors, in connection with our compliance and protection activities and in connection with business transfers as described in our Privacy Policy. </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h2>Changes to this CCPA Policy</h2>
                            <p>We reserve the right to amend this CCPA Policy at our discretion and at any time. When we make changes to this CCPA Policy, we will post the updated notice on the Website and update the CCPA Policy effective date at the top of the page. By accessing or using the Website and/or the Services, you acknowledge that you have read this CCPA Policy and, to the extent it applies to you, that you agree to the practices described in this CCPA Policy.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h2>Contact Us</h2>
                            <p>You may contact us by emailing us at privacy@wiseprezzie.com.</p>
                        </Col>
                    </Row>
                </Container>
            </article>
            {/* <Footer /> */}
        </>
    )
}

export default CCPAPolicy;