import {  useEffect } from 'react';
import TopNavbar from '../components/navbar/TopNavbar';
import { Switch, Route, withRouter, useLocation, Link } from "react-router-dom";
import Landing from '../components/HomeComponent';
import About from '../pages/about/About';
import Help from '../pages/help/Help';
import ManageConnections from '../pages/manage-connections/ManageConnections';
import Statistics from '../pages/statistics/Statistics';
import ProductRecommendationsPage from '../pages/product-recommendation/ProductRecommendationsPage';
import GiftSearch from '../pages/gift-recs-and-search/GiftsSearch';
import TakeTheTour from '../pages/take-the-tour/TakeTheTour';
import ErrorPage from '../pages/error-page/ErrorPage';
import SettingsPage from '../pages/settings/SettingsPage';
import LikedProducts from '../pages/liked-products/LikedProducts';
import TermsConditions from '../pages/terms-conditions/TermsConditions'
import PrivacyPolicy from '../pages/privacy-policy/PrivacyPolicy'
import CookiesPolicy from '../pages/cookies-policy/CookiesPolicy'
import CCPAPolicy from '../pages/ccpa-policy/CCPAPolicy'
import SubscriptionWall from '../pages/subscription-paywall/SubscriptionWall'
import PaymentCancelled from '../pages/subscription-paywall/PaymentCancelled'
import PaymentSuccess from '../pages/subscription-paywall/PaymentSuccess'
import HomePage from '../pages/home-page/HomPage'
import logo from '../assets/logo/WP.svg';
import Footer from '../components/footer/Footer';
import { useRecoilValue } from 'recoil';
import { userState } from '../state/atoms';

const Routes = withRouter(({ location }) => {

    const user = useRecoilValue(userState);
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div>
            {location.pathname !== "/subscription-paywall" && <TopNavbar logo={logo} />}
            <Switch>
                <Route exact path="/about" component={About} />
                <Route exact path="/help" component={Help} />
                <Route exact path="/reset-password/" component={Landing} />
                <Route exact path="/invite-connection/" component={Landing} />
                <Route exact path="/successful-registration/" component={Landing} />
                <Route exact path="/terms-conditions" component={TermsConditions} />
                <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                <Route exact path="/cookies-policy" component={CookiesPolicy} />
                <Route exact path="/ccpa-policy" component={CCPAPolicy} />
                <Route exact path="/" component={Landing} />
                <Route exact path="/subscribe" component={SubscriptionWall} />
                {user.isLoggedIn && <Route exact path="/payment/cancelled" component={PaymentCancelled} />}
                {user.isLoggedIn && <Route exact path="/payment/success" component={PaymentSuccess} />}
                {!user.isLoggedIn && <Route exact path="/tour" component={TakeTheTour} />}
                {!user.isLoggedIn && <Route exact path="/" component={HomePage} />}
                {user.isLoggedIn && <Route exact path="/relationships" component={ManageConnections} />}
                {/* { user.isLoggedIn && <Route exact path="/my-profile" component={MyProfile} /> } */}
                {/* {user.isLoggedIn && <Route exact path="/connection-profile" component={UserConnectionPage} />} */}
                {user.isLoggedIn && <Route exact path="/product-recommendations" component={ProductRecommendationsPage} />}
                {user.isLoggedIn && <Route exact path="/search" component={GiftSearch} />}
                {user.isLoggedIn && <Route exact path="/settings" component={SettingsPage} />}
                {user.isLoggedIn && <Route exact path="/liked-products" component={LikedProducts} />}
                {user.isLoggedIn && <Route exact path="/statistics" component={Statistics} />}
                <Route exact path="/404" component={ErrorPage} />
                <Route component={ErrorPage} />
            </Switch>
            {location.pathname !== "/subscription-paywall" && <Footer />}
        </div>
    )
})

export default Routes;
