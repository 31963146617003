import React, { FunctionComponent, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import './BaseObjectDropdown.scss';

type ObjectDropdownProps = {
    title: string,
    options?: object[],
    onClick?: any,
}

const BaseObjectDropdown: FunctionComponent<ObjectDropdownProps> = ({ title, options, onClick }) => {
    const [choice, setChoice] = useState(title);
    useEffect(() => {
        if (options != undefined && options?.length > 0) {
            //setChoice(options[0]["value"])
            //onItemClick(options[0]);
        }
        setChoice(title)
    }, [title])

    function onItemClick(option) {
        if (typeof option == "object") {
            onClick(option["value"])
            setChoice(option["key"])
        }
        else {
            onClick(option)
            setChoice(option)
        }
    }

    return (
        <Dropdown className="dropdown-object">
            <Dropdown.Toggle value={choice} className="dropdown-toggle" id="dropdown-basic">
                {choice}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {options?.map((option, index) => (
                    typeof option == "object"
                        ?
                        <Dropdown.Item key={index} onClick={() => onItemClick(option)} >{option["key"]}</Dropdown.Item>
                        :
                        <Dropdown.Item key={index} onClick={() => onItemClick(option)} >{option}</Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default BaseObjectDropdown;