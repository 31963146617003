import Reac, { useState, useContext } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import './GoogleFbLoginMissingDataModal.scss';
import BaseButton from '../../base-components/BaseButton';
import ToggleButtonModal from '../modal-components/ToggleButtonModal';
import ProgressLineModal from '../modal-components/ProgressLineModal';
import HeaderModal from '../modal-components/HeaderModal';
import arrowRight from '../../../assets/icons/right-arrow.svg';
import { DatePickerFormComponent } from '../../form-components/DatePickerFormComponent';
import { IDate } from "../../../types/types-internal";
import { IsNullOrEmpty } from "../../../utils/Utils";
import { useRecoilState } from 'recoil'
import { userState } from "../../../state/atoms";

const GoogleFbLoginMissingDataModal = (props) => {

    const [user, setUser] = useRecoilState(userState)

    const [genderRequired, setGenderRequired] = useState(false)
    const [birthdayRequired, setBirthdayRequired] = useState(false)

    const [gender, setGender] = useState("");
    const [ageRange, setAgeRange] = useState("1");

    const [date, setDate] = useState<Date>()

    function onGenderChange(gender) {
        setGender(gender)
        setGenderRequired(false)
    }

    function updateCurrentUser() {
        let hasError = false;

        if (gender == "") {
            setGenderRequired(true)
            hasError = true;
        }

        if (date == undefined) {
            setBirthdayRequired(true)
            hasError = true;
        }
        if(hasError){
            return
        }

        if(!IsNullOrEmpty(props.connectionId))
        {
            fetch("api/Users/UpdateUser", {
                "method": "PUT",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                },
                "body": JSON.stringify({
                    connectionId: props.connectionId,
                    gender: gender,
                    birthday: date,
                    agerange: parseInt(ageRange),
                })
            })
            .then(response => {
                props.onHide()
                props.onReady()
            })
            .then(data => console.log(data))
            .catch(err => {
                console.log(err);
            });
        }
        else
        {
            fetch("api/Users/UpdateUser", {
                "method": "PUT",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                },
                "body": JSON.stringify({
                    userId: user.userId,
                    gender: gender,
                    birthday: date,
                    agerange: parseInt(ageRange),
                })
            })
            .then(response => {
                if (response.ok) {

                    setUser({...user,
                        userId: user.userId,
                        isLoggedIn: true,
                        birthday: date,
                        ageRange: ageRange,
                        gender: gender,
                    });
                }
                else {
                    response.text().then(text => console.log(text));
                }
                props.onHide()
                props.onReady()
            })
            .then(data => console.log(data))
            .catch(err => {
                console.log(err);
            });
        }   
    }

    return (
        <section className="google-fb-missing-data-modal active">
            <Container fluid>
                <Row>
                    <Col sm={12} lg={12} className="avatar-info">
                    <h3> Please fill in gender and birthdate </h3>

                        <Row>
                            <Col>
                                <h5>Gender</h5>
                                <ToggleButtonModal onGenderChange={onGenderChange} />
                                <span className="required-modal-field" hidden={!genderRequired}> Gender is required </span>
                            </Col>
                        </Row>
                        <Row>
                            <DatePickerFormComponent value={date} onChange={setDate} />
                            <span className="required-modal-field" hidden={!birthdayRequired}> Birthday is required </span>
                        </Row>
                        <Row>
                            <Col>
                                <h5><span>OR</span> Age range</h5>
                                <Row>
                                    <Col sm={12} lg={4}>
                                        <select className="DatePicker" placeholder="Age Range" onChange={(e) => setAgeRange(e.currentTarget.value)}>
                                            <option value="1">Below 21</option>
                                            <option value="2">22-30</option>
                                            <option value="3">31-40</option>
                                            <option value="4">41-50</option>
                                            <option value="5">51-60</option>
                                            <option value="6">61+</option>
                                        </select>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <BaseButton
                            onClick={updateCurrentUser}
                            title="Update user info"
                            icon={arrowRight}
                            alt="Right Arrow"
                            className="arrow-animation mt-5">
                        </BaseButton>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default GoogleFbLoginMissingDataModal;