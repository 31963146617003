import { BrowserRouter } from "react-router-dom";
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import Routes from './routes/Routes';
import AuthenticateContext from './context/AuthenticateContext';
import TagManager from 'react-gtm-module'
import { useEffect } from "react";
import { RecoilRoot } from "recoil";

function App() {

  const tagManagerArgs = {
    gtmId: 'GTM-MQ3VX6X'
  }

  TagManager.initialize(tagManagerArgs)

  return (
    <RecoilRoot>
        <BrowserRouter>
          <AuthenticateContext />
          <Routes />
        </BrowserRouter>
    </RecoilRoot>
  );
}

export default App;
