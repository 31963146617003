import { FunctionComponent } from "react";
import './BaseTooltip.scss';

type ButtonProps = {
    title: string,
    className?: string
}

const BaseTooltip: FunctionComponent<ButtonProps> = ({ title }) => {
    return (
        <div className="tip">
            {title}
        </div>
    )
}

export default BaseTooltip;