import React, { FunctionComponent, useContext, useState, useEffect } from "react";
import { NavLink, Link, useHistory } from 'react-router-dom';
import './TopNavbar.scss';
import LoginField from '../login/LoginField';
import userProfile from '../../assets/icons/user-profile.svg';
import ProfileDropdown from '../profile-components/ProfileDropdown';
import BaseButton from "../base-components/BaseButton";
import { SubscriptionType } from "../../enums/UserEnums";
import subscribeIcon from '../../assets/icons/comment-check.png';
import { useRecoilState } from 'recoil'
import { userState, selectedConnection } from '../../state/atoms'

import myRecIcon from '../../assets/icons/my-rec.svg'

type NavbarProps = {
    logo: string
}

const TopNavbar: FunctionComponent<NavbarProps> = ({ logo }) => {
    const [user, setUser] = useRecoilState(userState)
    const history = useHistory();

    const [scroll, setScroll] = useState(false);

    const [checked, setChecked] = useState(false)
    const handleClick = () => setChecked(!checked)

    useEffect(() => {
        if (typeof window !== "undefined") {
            window.addEventListener("scroll", () =>
                setScroll(window.pageYOffset > 10)
            );
        }
    }, []);

    const openSubscriptionPage = () => {
        history.push('/subscribe');
    }

    const checkMyProfile = () =>
    {
        if(window.location.pathname == "product-recommendations")
        {
            return true;
        }

        return false;
    }

    const [selectedConnectionState, setSelectedConnectionState] = useRecoilState(selectedConnection);

    const goToMyRecommendations = () => {
        setSelectedConnectionState({ connectionId: 0 })
        handleClick();
        history.push('/product-recommendations');
    }

    return (
        <nav className={`nav ${scroll ? "scrolled" : ""}`}>
            <Link to={user.isLoggedIn ? '/relationships' : '/'} className="logo">
                <img src={logo} alt="Wise Prezzie Logo" />
            </Link>
            <input className="menu-btn" type="checkbox" id="menu-btn" onClick={handleClick} defaultChecked={checked} />
            <label className="menu-icon" htmlFor="menu-btn"><span className="navicon"></span></label>
            <div className="nav-list">
                {/*<NavLink to={'/about'} onClick={handleClick} className="nav-item" activeClassName="active">About</NavLink> */}
                {user.isLoggedIn && <NavLink to={'/relationships'} onClick={handleClick} className="nav-item" activeClassName="active">Giftees</NavLink>}
                {!user.isLoggedIn && <NavLink to={'/tour'} onClick={handleClick} className="nav-item" activeClassName="active">Take the tour</NavLink>}
                {user.isLoggedIn && <NavLink to={'/product-recommendations'}
                    isActive={(match) => {
                        const queryString = window.location.search;
                        const urlParams = new URLSearchParams(queryString);
                        const id = urlParams.get("id");
                        if (match) { 
                            return id == null ? true : false;
                        }
                        return false;
                    }}
                    onClick={goToMyRecommendations} activeClassName="active" className="nav-item"> My Profile </NavLink> }
               
                {user.isLoggedIn
                    ?
                    <div className="nav-buttons-group">
                        <ProfileDropdown />
                        {user.subscription != SubscriptionType.BasicSubscription && <BaseButton
                            title="SUBSCRIBE"
                            className="subscribe-btn"
                            icon={subscribeIcon}
                            onClick={openSubscriptionPage} />}
                    </div>

                    : <LoginField
                        title="Login"
                        icon={userProfile} />}
            </div>
        </nav>
    )
}



export default TopNavbar;
