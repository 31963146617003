import feedback1 from '../../../assets/icons/feedback1.svg'
import feedback2 from '../../../assets/icons/feedback2.svg'
import feedback3 from '../../../assets/icons/feedback3.svg'
import feedback4 from '../../../assets/icons/feedback4.svg'
import feedback5 from '../../../assets/icons/feedback5.svg'

export const feedbackStatus = {
    'HATE': feedback1,
    'DISLIKE': feedback2,
    'NEUTRAL': feedback3,
    'LIKE': feedback4,
    'LOVE': feedback5,
}