import React, { FunctionComponent, useState } from "react";
import { IsNullOrEmpty } from "../../utils/Utils";
import BaseImage from "../base-components/BaseImage";
import './BaseFormInput.scss';

type FormInputProps = {
    value?: string | number,
    type?: string,
    name?: string,
    placeholder?: string,
    src?: string,
    onChange?: any,
    onBlur?: any,
    className?: string,
    autoFocus?: any,
    onKeyDown?: any,
}

const BaseFormInput: FunctionComponent<FormInputProps> = (props) => {

    const [value, setValue] = useState(props.value)
    const [imageHidden, setImageHidden] = useState(false)

    function onChange(e) {
        setValue(e)

        IsNullOrEmpty(e) ? setImageHidden(false) : setImageHidden(true)

        if (props.onChange) {
            props.onChange(e)
        }
    }

    return (
        <form className={props.className}>
            <label>
               { props.onKeyDown  
                ? <input autoFocus type={props.type} name={props.name} placeholder={props.placeholder} value={value} onKeyDown={(event) => props.onKeyDown(event)} onBlur={props.onBlur && ((e) => props.onBlur(e.currentTarget.value))} onChange={(e) => onChange(e.currentTarget.value)} />
                : <input autoFocus type={props.type} name={props.name} placeholder={props.placeholder} value={value} onBlur={props.onBlur && ((e) => props.onBlur(e.currentTarget.value))} onChange={(e) => onChange(e.currentTarget.value)} />
               }
                <BaseImage img={imageHidden ? "" : props.src as string} alt="" />
            </label>
        </form>
    )
}

export default BaseFormInput;
