import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import './EditProfileModalBody.scss';
import { Col, Container, Row } from 'react-bootstrap';
import BaseImageUpload from "../../base-components/BaseImageUpload";
import BaseButton from "../../base-components/BaseButton";
import ToggleButtonModal from '../modal-components/ToggleButtonModal';
import arrowRight from '../../../assets/icons/right-arrow.svg';
import HeaderModal from '../modal-components/HeaderModal';
import { IUser, IUserConnection, ILocation, IDate } from "../../../types/types-internal";
import { LocationFormComponent } from '../../form-components/LocationFormComponent';
import { DatePickerFormComponent } from '../../form-components/DatePickerFormComponent';
import { IsNullOrEmpty } from '../../../utils/Utils';
import { useRecoilState } from 'recoil'
import { userState } from "../../../state/atoms";

type EditProfileProps = {
    onEditFinished?: any,
    profile?: IUser | IUserConnection,
    updateProfile?: any,
    setShowModal?: any,
}

const EditProfileModalBody: FunctionComponent<EditProfileProps> = (props) => {

    const [user, setUser] = useRecoilState(userState)
    const [firstNameRequired, setFirstNameRequired] = useState(false)
    const [lastNameRequired, setLastNameRequired] = useState(false)
    const [genderRequired, setGenderRequired] = useState(false)
    const [birthdayRequired, setBirthdayRequired] = useState(false)
    const [locationRequired, setLocationRequired] = useState(false)

    const [profile, setProfile] = useState<IUser>({ ...props.profile });
    const [location, setLocation] = useState<ILocation>({ country: profile.country, state: profile.state, city: profile.city });
    const [relationship, setRelationship] = useState<string>(profile.relationship!);
    const [profileImageFile, setProfileImageFile] = useState<FormData>();
    const [profilePhotoUrl, setProfilePhotoUrl] = useState(props.profile?.profilePhotoDownloadUrl);
    const [date, setDate] = useState(profile?.birthday);

    const loadImage = (event) => {
        let file = event.target.files[0]
        const formData = new FormData()
        formData.append('userImage', file)

        setProfilePhotoUrl(URL.createObjectURL(file));
        setProfileImageFile(formData);
        setProfile({ ...profile, imagePathUrl: URL.createObjectURL(file) });
    }

    const [showSpinner, setShowSpinner] = useState(false)

    let hasError = false;

    const bundleAndUpdateProfile = () => {
        if (IsNullOrEmpty(profile.firstName)) {
            setFirstNameRequired(true)
            hasError = true;
        }
        else {
            setFirstNameRequired(false)
        }

        if (IsNullOrEmpty(profile.lastName)) {
            setLastNameRequired(true)
            hasError = true;
        }

        if (IsNullOrEmpty(profile.gender)) {
            setGenderRequired(true)
            hasError = true;
        }

        if (location.country == "Country" || IsNullOrEmpty(location.city)) {
            setLocationRequired(true)
            hasError = true;
        }

        if(hasError){
            return
        }

        profile.country = location.country;
        profile.city = location.city;
        profile.state = location.state;
        profile.relationship = relationship;

        if (date) {
            profile.birthday = new Date(date);
        }
        props.updateProfile(profile, profileImageFile);
        setShowSpinner(true)
        setProfile({ ...profile, birthday: profile.birthday, country: location.country, state: location.state, city: location.city, relationship: relationship });

        if (profile.isLoggedIn) {
            setUser({ ...user, userName: profile.firstName + " " + profile.lastName, firstName: profile.firstName, lastName: profile.lastName, relationship: relationship })
        }

        setTimeout(() => {
            setShowSpinner(false)
            props.setShowModal(false);
        }, 500)

    }

    const [isActive, setActive] = useState(false);

    const handleToggle = () => {
        setActive(!isActive);
    };

    const initials = `${profile.firstName?.substring(0, 1)}${profile.lastName?.substring(0, 1)}`

    return (
        <div className="pb-4">
            <HeaderModal
                heading={profile.isRealUser ? "User Profile" : "Edit Profile"}
                span=""
                paragraph=""
            />

            <section className={profile.isRealUser ? "profile-container disabled" : "profile-container"}>
                <Container>
                    <Row>
                        <Col lg={3} xl={2} className="d-flex align-items-end">
                            <BaseImageUpload img={profilePhotoUrl} imageHandler={(e) => loadImage(e)} alt="" initials={initials} />
                        </Col>
                        <Col className="profile-info-container">
                            <section className="profile-info">
                                <span>User profile</span>
                                <form>
                                    <Row className="pt-3">
                                        <Col className="mb-3" sm={12} md={6} xl={4}>
                                            <label>
                                                <input
                                                    type="text"
                                                    name="firstName"
                                                    placeholder="First name *"
                                                    value={profile.firstName}
                                                    onChange={(e) => setProfile({ ...profile, firstName: e.currentTarget.value })}
                                                />
                                            </label>
                                            <span className="required-modal-field" hidden={!firstNameRequired}> First name is required </span>
                                        </Col>
                                        <Col className="mb-3" sm={12} md={6} xl={4}>
                                            <label>
                                                <input
                                                    type="text"
                                                    placeholder="Last name *"
                                                    value={profile.lastName}
                                                    onChange={(e) => setProfile({ ...profile, lastName: e.currentTarget.value })}
                                                    name="lastName"
                                                />
                                            </label>
                                            <span className="required-modal-field" hidden={!lastNameRequired}> Last name is required </span>
                                        </Col>
                                        <Col sm={12} md={6} xl={4} className="mb-3">
                                            {!profile.isLoggedIn &&
                                                <label>
                                                    <input
                                                        type="text"
                                                        placeholder="Email"
                                                        value={profile.email || profile.connectionEmail}
                                                        onChange={(e) => setProfile({ ...profile, connectionEmail: e.currentTarget.value })}
                                                        name="email"
                                                    />
                                                </label>}
                                        </Col>
                                    </Row>
                                </form>
                            </section>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className={profile.isRealUser ? "edit-profile disabled" : "edit-profile"}>
                <Container fluid>
                    <Container>
                        <Row className="d-block pt-3">
                            <Col lg={12} xl={4} className="avatar-info">
                                <h5>Gender *</h5>
                                <ToggleButtonModal onGenderChange={(e) => setProfile({ ...profile, gender: e })} gender={profile.gender} />
                                <span className="required-modal-field" hidden={!genderRequired}> Gender is required </span>
                            </Col>
                            <DatePickerFormComponent value={date} onChange={setDate} />
                            <span className="required-modal-field" hidden={!birthdayRequired}> Birthday is required </span>
                            <Row className="pt-3">
                                <Col xs={12} sm={6} md={6} lg={4} className="pt-3">
                                    <h5>Age range</h5>
                                    <div className={`select-wapper ${isActive ? "open" : ""}`}>
                                        <select className="DatePicker" placeholder="Age Range" value={profile.ageRange} onClick={handleToggle} onChange={(e) => setProfile({ ...profile, ageRange: e.currentTarget.value })}>
                                            <option value="Below21">Below 21</option>
                                            <option value="Between21And30">22-30</option>
                                            <option value="Between31And40">31-40</option>
                                            <option value="Between41And50">41-50</option>
                                            <option value="Between51And60">51-60</option>
                                            <option value="Above60">61+</option>
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            <LocationFormComponent location={location} setLocation={setLocation} />
                            <span className="required-modal-field" hidden={!locationRequired}> Location is required </span>

                            <Col className="pt-3">
                                <BaseButton
                                    showSpinner={showSpinner}
                                    onClick={() => bundleAndUpdateProfile()}
                                    title="Update user info"
                                    icon={arrowRight}
                                    alt="Right Arrow"
                                    className="pt-3 arrow-animation">
                                </BaseButton>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </section>
        </div>
    )
}

export default EditProfileModalBody;
