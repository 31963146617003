import { useState,  useEffect } from 'react'
import { useHistory } from "react-router-dom"
import './SettingsPage.scss'
import YesNoModal from '../../components/modal/modal-yes-no/YesNoModal'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import SettingsTab from './tab/SettingsTab'
import userIcon from '../../assets/icons/user-icon-grey.svg'
import lockIcon from '../../assets/icons/lock.svg'
import paymentsIcon from '../../assets/icons/payments.svg'
import bellIcon from '../../assets/icons/bell.svg'
import noneIcon from '../../assets/icons/none.svg'
import personIcon from '../../assets/icons/user-icon.svg'
import PersonImage from '../../components/person-card/PersonImage'
import BaseImage from '../../components/base-components/BaseImage'
import ResetPasswordModal from '../../components/modal/modal-reset-password/ResetPasswordModal';
import axios from 'axios'
import { useRecoilState } from 'recoil'
import { userState } from '../../state/atoms'

const SettingsPage = () => {

    let history = useHistory();
    const [user, setUser] = useRecoilState(userState);
    const [showSpinner, setShowSpinner] = useState(false)
    const [customerId, setCustomerId] = useState('');

    const dropdownOptions = ["I no longer need it", "I found a better tool", " The product doesn't work/do what I need", "It's too expensive", "Other"]

    useEffect(() => {
        (async () => {
            let payment = await axios.get('/api/Payment/CheckPaymentStatus');
            setCustomerId(payment.data.customerId);
        })();
    });
    async function deactivateProfile() {

        setShowSpinner(true)
        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        }

        await fetch('api/Auth/DeactiveCurrentUser', settings)
        setUser({
            userName: '',
            userId: '',
            isLoggedIn: false,
        });
        history.push('/')
        setShowSpinner(false)
    }


    const displayUserName = () => {
        console.log(user)
        if ((user.firstName == "" || user.firstName == undefined) || (user.lastName == "" || user.lastName == undefined)) {
            return user.userName
        }

        return user.firstName + " " + user.lastName
    }

    const initials = displayUserName()?.split(" ").map((n) => n[0]).join("")
    const openCustomerPortal = () => {
        if (customerId) {
            (async () => {
                let customerPortal = await axios.post('/api/payment/CustomerPortal', { customerId })
                window.location.href = customerPortal.data;
            })();
        }
        else {
            history.push('/subscribe');
        }
    }

    return (
        <>
            <Tabs>
                <TabList>
                    <div className="settings-profile-container">
                        <PersonImage
                            personImg={user.profilePhotoDownloadUrl}
                            personAlt="User"
                            personIcon={personIcon}
                            initials={initials} />
                        <section className="profile-info">
                            <h3>{displayUserName()}</h3>
                        </section>
                    </div>
                    <Tab>
                        <SettingsTab
                            img={lockIcon}
                            alt="User icon"
                            label="Password & Security" />
                    </Tab>
                    <Tab onClick={openCustomerPortal}>
                        <SettingsTab
                            img={paymentsIcon}
                            alt="User icon"
                            label="Manage subscriptions" />
                    </Tab>
                    <Tab>
                        <SettingsTab
                            img={noneIcon}
                            alt="User icon"
                            label="Deactivate account" />
                    </Tab>
                </TabList>
                <TabPanel>
                    <div className="panel-content">
                        <h2>Password & Security</h2>
                        <div className="deactivate-container lock-icon">
                            <BaseImage
                                img={lockIcon}
                                alt="Reset password" />
                            <div className="deactivate-button-container">
                                <span>You are about to change your password.</span>
                                <ResetPasswordModal title="Change your password" icon="" fromSettings={true} changePassword={true} />
                            </div>
                        </div>
                        <p>You will receive an email confirmation of your password change.</p>
                    </div>
                </TabPanel>
                <TabPanel >

                </TabPanel>
                <TabPanel>
                    <div className="panel-content">
                        <h2>Deactivate Account</h2>
                        <div className="deactivate-container">
                            <BaseImage
                                img={noneIcon}
                                alt="Deactivete profile" />
                            <div className="deactivate-button-container">
                                <span>Are you sure you want to deactivate your account?</span>
                                <YesNoModal
                                    modalTitle="Deactivate Your Profile"
                                    icon=""
                                    buttonTitle="Yes, Deactivate Account"
                                    negativeText="Cancel"
                                    positiveText="Deactivate"
                                    subtitle="You can reactivate it by logging in again."
                                    onPositive={() => deactivateProfile()}
                                    showSpinner={showSpinner}
                                    showOptions={true}
                                    options={dropdownOptions}
                                />
                            </div>
                        </div>
                        <p>You can reactivate your account at any time. You need to save your login details and also contact our customer support.</p>
                    </div>
                </TabPanel>
            </Tabs>
        </>
    )
}

export default SettingsPage;