import React from 'react'
import './GiftsSearch.scss'
import AdminNavbar from "../../components/navbar/AdminNavbar"
import { Col, Container, Row } from 'react-bootstrap'
import UserProfileContainer from '../../components/profile-components/UserProfileContainer'
import CategoriesNavbar from '../../components/navbar/CategoriesNavbar'
import CategorySearchBar from '../../components/category-search-bar/CategorySearchBar'
import CategoryFilters from '../../components/category-filters/CategoryFilters'
import ProductCard from '../../components/product-components/ProductCard'
import product1 from '../../assets/images/product1.png'
import product2 from '../../assets/images/product2.png'
import product3 from '../../assets/images/product3.png'
import product4 from '../../assets/images/product4.png'
import product5 from '../../assets/images/product5.png'
import product6 from '../../assets/images/product6.png'

const GiftsSearch = () => {
    return (
        <>
            <AdminNavbar />
            <article className="gifts-and-search-page">
                <Container fluid>
                    <Container >
                        <Row>
                            <Col className="recommended-products-container">
                                {/* <ProductCard
                                    ribbon="Designer"
                                    img={product1}
                                    alt="Product name"
                                    title="Product Name Here"
                                    brand="Nike"
                                    price="25" />
                                <ProductCard
                                    ribbon="Designer"
                                    img={product5}
                                    alt="Product name"
                                    title="Product Name Here"
                                    brand="Nike"
                                    price="25" />
                                <ProductCard
                                    ribbon="Designer"
                                    img={product2}
                                    alt="Product name"
                                    title="Product Name Here"
                                    brand="Nike"
                                    price="25" />
                                <ProductCard
                                    ribbon="Designer"
                                    img={product6}
                                    alt="Product name"
                                    title="Product Name Here"
                                    brand="Nike"
                                    price="25" /> */}
                            </Col>
                        </Row>
                    </Container>
                    <CategoriesNavbar />
                    <CategorySearchBar categoryName="Clothing" />
                </Container>
                <Container>
                    <Row>
                        <Col md={4} lg={3}>
                            <CategoryFilters />
                        </Col>
                        <Col md={8} lg={9}>
                            <Col className="recommended-products-container search">
                                {/* <ProductCard
                                    ribbon="Designer"
                                    img={product1}
                                    alt="Product name"
                                    title="Product Name Here"
                                    brand="Nike"
                                    price="25" />
                                <ProductCard
                                    ribbon="Designer"
                                    img={product5}
                                    alt="Product name"
                                    title="Product Name Here"
                                    brand="Nike"
                                    price="25" />
                                <ProductCard
                                    ribbon="Designer"
                                    img={product2}
                                    alt="Product name"
                                    title="Product Name Here"
                                    brand="Nike"
                                    price="25" />
                                <ProductCard
                                    ribbon="Designer"
                                    img={product4}
                                    alt="Product name"
                                    title="Product Name Here"
                                    brand="Nike"
                                    price="25" />
                                <ProductCard
                                    ribbon="Designer"
                                    img={product6}
                                    alt="Product name"
                                    title="Product Name Here"
                                    brand="Nike"
                                    price="25" />
                                <ProductCard
                                    ribbon="Designer"
                                    img={product3}
                                    alt="Product name"
                                    title="Product Name Here"
                                    brand="Nike"
                                    price="25" /> */}
                            </Col>
                        </Col>
                    </Row>
                </Container>
            </article>
        </>
    )
}

export default GiftsSearch;