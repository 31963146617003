import React, { FunctionComponent, useState, useContext, useEffect } from 'react';
import { Container, Row, Col, Dropdown } from 'react-bootstrap';
import './LoginForm.scss';
import BaseButton from '../base-components/BaseButton';
import HeaderModal from '../modal/modal-components/HeaderModal';
import facebookIcon from '../../assets/icons/facebook-login.svg';
import googleIcon from '../../assets/icons/google-login.svg';
import { useHistory } from "react-router-dom";
import ResetPasswordModal from '../modal/modal-reset-password/ResetPasswordModal';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import {IsNullOrEmpty} from '../../utils/Utils';
import { useRecoilState } from 'recoil';
import { userState } from '../../state/atoms';

type LoginProps = {
    closeModal: any,
    hideModal?: any
}

const LoginForm = (props: LoginProps) => {
    const history = useHistory();

    const [user, setUser] = useRecoilState(userState)

    const [email, setEmail] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [invalidLogin, setInvalidLogin] = useState<string>('');

    const [loading, setLoading] = React.useState(false)

    const handleSubmit = (e: any) => {
        setLoading(true)
        setTimeout(() => {
        }, 1000)
        e.preventDefault();
        fetch('api/Auth/Login', {
            'method': 'POST',
            'headers': {
                'content-type': 'application/json',
                'accept': 'application/json'
            },
            'body': JSON.stringify({
                email: email,
                password: password
            })
        })
            .then(response => response.json())
            .then(response => {
                logTheUserIn(response);
            })
            .catch(err => {
                console.log(err);
                setLoading(false)
            });

    }

    let logTheUserIn = (response) => {
        setLoading(false)
        console.log("LOG THE USER IN", response);
        if (!response.userId) {
            setInvalidLogin('Invalid');
        }
        else {
            setInvalidLogin('');
            props.closeModal();
            const currentUserId = response.userId as string;
          
            let firstName = response.firstName;
            let lastName = response.lastName;
            let email = response.email;
            let birthday = response.birthday;
            let gender = response.gender;
            let ageRange = response.ageRange;
            let imageUrl = response.imagePathUrl;
            let reactivated = response.reactivated;

            setUser({
                ...user,
                firstName: firstName,
                lastName: lastName,
                userName: firstName + " " + lastName,
                birthday: birthday,
                gender: gender,
                ageRange: ageRange,
                email: email,
                userId: currentUserId,
                isLoggedIn: true,
                reactivated: reactivated,
                imagePathUrl: imageUrl,
                country: response.country,
                state: response.state,
                city: response.city
            });

            history.push('/relationships')
            // fetchUser(currentUserId)
        }
    }

    async function fetchUser(currentUserId) {
        const userInfoResponse = await fetch('api/Users/GetCurrentUserInfo')
        let userInfo = await userInfoResponse.json()

        let firstName = userInfo.firstName;
        let lastName = userInfo.lastName;
        let email = userInfo.email;
        let birthday = userInfo.birthday;
        let gender = userInfo.gender;
        let ageRange = userInfo.ageRange;
        let imageUrl = userInfo.imagePathUrl;

        setUser({
            firstName: firstName,
            lastName: lastName,
            userName: firstName + " " + lastName,
            birthday: birthday,
            gender: gender,
            ageRange: ageRange,
            email: email,
            userId: currentUserId,
            isLoggedIn: true,
            imagePathUrl: imageUrl,
            country: userInfo.country,
            state: userInfo.state,
            city: userInfo.city
        });
    }


    const handleSignUp = () => {
        history.push('/')
        props.closeModal()
    }

    const responseFacebook = (response) => {
        console.log(response.accessToken);

        fetch('api/Auth/FacebookLogin', {
            'method': 'POST',
            'headers': {
                'content-type': 'application/json',
                'accept': 'application/json'
            },
            'body': JSON.stringify(response.accessToken)
        })
            .then(response => response.json())
            .then(response => {
                logTheUserIn(response);
            })
            .catch(err => {
                console.log(err);
                setLoading(false)
            });
    }

    const responseGoogle = (response) => {
        console.log(response.tokenObj.id_token);

        setLoading(true)

        fetch('api/Auth/GoogleLogin', {
            'method': 'POST',
            'headers': {
                'content-type': 'application/json',
                'accept': 'application/json'
            },
            'body': JSON.stringify(response.tokenObj.id_token)
        })
            .then(response => response.json())
            .then(response => {
                logTheUserIn(response);
            })
            .catch(err => {
                console.log(err);
                setLoading(false)
            });
    }

    useEffect(() => 
    {
        var url = new URL(window.location.toString())
        if(url.toString().includes("successful-registration")){
       
            let email = url.searchParams.get("email")

            if(!IsNullOrEmpty(email))
            {
                setEmail(email?.toString())
            }
        }
    }, []);

    return (
        <section className='login-form'>
            <HeaderModal
                heading='Login'
                span=''
                paragraph=''
            />

            <Container fluid>
                <Row>
                    <Col>
                        <form id='login-form'>
                            <label>
                                <input id="emailLoginField" type='email' value={email} onChange={e => setEmail(e.target.value)} placeholder='Email Address' />
                            </label>
                            <label>
                                <input type='password' value={password} onChange={e => setPassword(e.target.value)} placeholder='Password' />
                            </label>
                            {invalidLogin
                                ? <span className={'invalid-credentials'}>Invalid Credentials</span>
                                : null}
                            <ResetPasswordModal title="Forgot your password?" icon="" hideModal={(e) => props.hideModal(e)} />
                        </form>
                        <BaseButton
                            form='login-form'
                            onClick={handleSubmit}
                            title='Login'
                            showSpinner={loading}
                            icon=''
                            alt=''>
                        </BaseButton>
                        <div className="social-login-wrapper">
                            <GoogleLogin
                                className="google-button"
                                clientId="58223197108-3d92p6jqjo9skufsdfh16oopsrc7mn2a.apps.googleusercontent.com"
                                onSuccess={responseGoogle}
                                buttonText="Login with google"
                            />
                            <FacebookLogin
                                appId="266070165375750"
                                autoLoad={false}
                                icon={facebookIcon}
                                fields="name,email,picture"
                                callback={responseFacebook} />
                        </div>
                        <p>No account? Sign up for free!</p>
                        <BaseButton
                            onClick={handleSignUp}
                            title='Sign Up'
                            icon=''
                            alt=''>
                        </BaseButton>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default LoginForm;
