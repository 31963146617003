import React, { FunctionComponent, useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap';
import './PlaceholderConnectionPopUp.scss';
import BaseButton from '../../components/base-components/BaseButton';
import YourConnectionModal from '../../components/modal/modal-your-connection/YouConnectionModal';
import ProfileCreatedModal from '../../components/modal/modal-profile-created/ProfileCreatedModal';
import EventModal from '../../components/modal/modal-event/EventModal';
import { IUserConnection } from "../../types/types-internal";
import ManageConnectionModalContainer from './ManageConnectionModalContainer';

interface Props {
    show: boolean,
    onHide: any,
    onShow: any,
    onNewConnection?: any,
    onNextModal?: any,
    addConnection?: any
}

function PlaceholderConnectionPopUp(props: Props) {

    const [userName, setUserName] = useState("")
    const [connectionId, setConnectionId] = useState()
    const [connection, setConnection] = useState<IUserConnection>()
    const [activeModal, setActiveModal] = useState(0);


    function onNewPlaceholderConnection(e) {
        setUserName(e.firstName + " " + e.lastName)
        setConnectionId(e.connectionId)
        setConnection(e)

        props.onNewConnection(0)
    }

    function onNextModal() {
        setActiveModal(activeModal + 1);
    }

    function onHide() {
        props?.onHide();
        setActiveModal(0);
    }

    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onExit={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ManageConnectionModalContainer 
                addConnection={props.addConnection}
                onNewConnection={props.onNewConnection}/>

                {/* {activeModal == 0 && <YourConnectionModal
                    title="Add Relationship"
                    onNewConnection={onNewPlaceholderConnection}
                    addConnection={true}
                    onNextModal={onNextModal}
                />}
                {activeModal == 1 && <EventModal
                    connectionId={connectionId}
                    onNextModal={onNextModal} />}           

                {activeModal == 2 && <ProfileCreatedModal
                    onHide={onHide}
                    user={{ userName: connection?.firstName + " " + connection?.lastName, connectionId: connectionId }}
                    connectionId = {connection?.connectionId}
                    onNextModal={onNextModal} />} */}

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

type ButtonProps = {
    title: string,
    icon: string,
    onNewConnection?: any,
    onNextModal?: any,
    addConnection: any
}

const App: FunctionComponent<ButtonProps> = (props) => {

    const [modalShow, setModalShow] = React.useState(false);

    function onNewPlaceholderConnection(e) {
        props.onNewConnection(e)
    }

    function onClick() {
        setModalShow(true)
    }

    return (
        <>
            <BaseButton
                onClick={onClick}
                title={props.title}
                icon={props.icon}
                alt="">
            </BaseButton>
            <PlaceholderConnectionPopUp
                onNextModal={(e) => props.onNextModal(e)}
                onNewConnection={(e) => onNewPlaceholderConnection(e)}
                show={modalShow}
                onHide={() => setModalShow(false)}
                onShow={() => setModalShow(true)}
                addConnection={props.addConnection}
            />
        </>
    );
}

export default App;