import { useState } from "react";
import { useRecoilState } from "recoil";
import { IUserOccasion } from "../types/types-internal";

const GetOccasions = async (connectionId) => {
    if (!connectionId) {
        connectionId = 0;
    }
    const occasionsResponse = await fetch('api/Occasions/GetUserOccasions?userConnectionId=' + connectionId);
    return await occasionsResponse.json();
}

const AddOccasion = (connectionId, occasion) => {

}

const UpdateOccasion = (connectionId, occasion) => {

}

const RemoveOccasion = (connectionId, occasion) => {

}

const OccasionService = {
    GetOccasions,
    AddOccasion,
    UpdateOccasion,
    RemoveOccasion
};
export default OccasionService;