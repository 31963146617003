import './SubscriptionWall.scss'
import { Link, useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap'
import logo from '../../assets/logo/WP.svg'
import BaseCheckbox from '../../components/base-components/BaseCheckbox';
import BaseButton from '../../components/base-components/BaseButton';
import arrowRight from '../../assets/icons/right-arrow.svg';
import BaseImage from '../../components/base-components/BaseImage';
import subscriptionsPaywall from '../../assets/images/subscriptions-paywall.png'
import axios from 'axios';
import { useState } from 'react';

const SubscriptionPaywall = () => {
    const subscriptionType = 'price_1KPOvjLQ7nkEirszJfYhIC5v';
    const [hasAgreed, setHasAgreed] = useState(false);
    const [showError, setShowError] = useState(false);
    const history = useHistory();

    function goToHome() {
        history.push('/relationships')
    }

    const agreedToTheTerms = () => {
        setHasAgreed(true);
        setShowError(false);
    }

    const subscribe = (priceKey) => {
        if (hasAgreed) {
            (async () => {
                let result = await axios.post('/api/payment/createcheckoutsession', { priceKey });
                setShowError(false);
                window.location.href = result.data;
            })();
        } else {
            setShowError(true);
        }
    }

    return (
        <>
            <article className="subscription-paywall-container">
                <Container>
                    <Row className="subscribe-row">
                        <Col sm={12} md={12} lg={4} className='subscriptions-content'>
                            <h1>
                                Subscribe to take advantage of the full WisePrezzie experience
                            </h1>
                            <p>
                                Finding great gifts is hard, we know. We built Wise Prezzie because we’re on a mission to help you find impactful gifts while saving you from the hassle.
                            </p>
                            <BaseImage img={subscriptionsPaywall} />
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <aside className='premium'>
                                <h2>Premium Experience</h2>
                                <p>Get the monthly subscription now.</p>
                                <Row className='subscriptions-list'>
                                    <strong>$7.99 dollars per month</strong>
                                </Row>
                                <ul>
                                    <li>Add as many connections you'd like to your gift giving list</li>
                                    <li>Receive unlimited gift recommendations</li>
                                    <li>You can save gift giving events for everyone you can think of!</li>
                                </ul>
                                <a className='d-flex mb-2' href="/terms-conditions">Terms and Conditions</a>
                                <BaseCheckbox
                                    label='I agree to the terms and conditions'
                                    value={hasAgreed}
                                    onChange={agreedToTheTerms} />
                                <BaseButton
                                    className="arrow-animation"
                                    title="Subscribe"
                                    icon={arrowRight}
                                    alt="Subscribe"
                                    onClick={() => subscribe(subscriptionType)}
                                />
                                {showError
                                    ? <span className='error-message'>Please view and agree the terms and conditions to proceed</span>
                                    : null}
                            </aside>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <h3>Your Current plan</h3>
                            <aside>
                                <h2>Free Trial</h2>
                                <p>Basic experience with limited functionality.</p>
                                <Row className='subscriptions-list'>
                                    <Col>
                                    <p>
                                        Experience WisePrezzie features for only 1 connection
                                    </p>
                                    </Col>
                                </Row>
                                <BaseButton
                                    className="arrow-animation"
                                    title="Continue"
                                    icon={arrowRight}
                                    alt="Continue"
                                    onClick={() => goToHome()}
                                />
                            </aside>
                        </Col>

                    </Row>
                </Container>
            </article>
        </>
    )
}

export default SubscriptionPaywall;