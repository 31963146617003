import React, { FunctionComponent } from 'react'
import './TourSection.scss'
import ScrollAnimation from 'react-animate-on-scroll';
import 'animate.css/animate.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import BaseImage from '../../../components/base-image/BaseImage'
import line from '../../../assets/svg/tour-line.svg'
import BaseButton from '../../../components/base-components/BaseButton';
import arrowRight from '../../../assets/icons/right-arrow.svg';
import Modal from '../../../components/modal/PopUp';
import { useHistory } from "react-router-dom";


type TourSectionProps = {
    img: string,
    alt?: string,
    step: string,
    title: string,
    subtitle: string,
    onClick?: any,
}

const TourSection: FunctionComponent<TourSectionProps> = ({ img, alt, title, subtitle, step, onClick }) => {
   
    const history = useHistory();

    function goToHome()
    {
        history.push('/')
    }

    return (
        <section className="tour-container">
            <Container >
                <Row>
                    <Col sm={6} xl={7} className="line-path">
                        <ScrollAnimation animateIn='animate__pulse'
                            animateOnce={false}>
                            <BaseImage
                                img={img}
                                alt={alt}
                            />
                        </ScrollAnimation>
                    </Col>
                    <Col sm={6} xl={5} className="tour-content-wrapper">
                        <div className="decoration">
                            <ScrollAnimation animateIn='animate__zoomIn'
                                animateOnce={true}
                                delay={1300}
                                duration={0.5}>
                                <div className="tour-step" onClick={onClick}>
                                    <span>{step}</span>
                                </div>
                            </ScrollAnimation>
                        </div>
                        <ScrollAnimation animateIn='animate__fadeInRight'
                            animateOnce={true}
                            delay={3}>
                            <div className="tour-text">
                                <h1>{title}</h1>
                                <p>{subtitle}</p>
                                <BaseButton
                                    className="tour-button arrow-animation"
                                    title="Give Wiseprezzie a Try"
                                    icon={arrowRight}
                                    alt="Give Wiseprezzie a Try"
                                    onClick={() => goToHome()}
                                />
                            </div>
                        </ScrollAnimation>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default TourSection;