import { useContext, useEffect, useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import './LikedProducts.scss';
import { useHistory, useLocation } from "react-router-dom";
import ProductCard from '../../components/product-components/ProductCard'
import { IProduct, IUserOccasion } from "../../types/types-internal";
import BaseButton from "../../components/base-components/BaseButton";
import BaseImage from '../../components/base-components/BaseImage'
import gift from '../../assets/icons/giftbox.svg'
import OccasionEventsListComponent from '../../components/occasion-components/OccasionEventsListComponent';
import { useRecoilState } from 'recoil'
import { userState } from "../../state/atoms";

const LikedProducts = (props) => {

    const history = useHistory();
    const [user, setUser] = useRecoilState(userState)
    const [likedProducts, setLikedProducts] = useState<IProduct[]>([]);
    const [occasions, setOccasions] = useState<IUserOccasion[]>([])
    const [currentOccasionId, setCurrentOccasionId] = useState(0);
    const [anchor, setAnchor] = useState(0);
    const [connectionId, setConnectionId] = useState(-1);
    const [label, setLabel] = useState('');
    let location = useLocation();

    async function getOccasions() {
        try {
            let connectionId = location.search.toString().replace('?connectionId=', '');
            if (!connectionId) {
                connectionId = 0;
            }
            const userConnectionOccasionsResponse = await fetch('api/Occasions/GetUserOccasions?userConnectionId=' + connectionId)
            var userConnectionOccasions = await userConnectionOccasionsResponse.json()
            userConnectionOccasions = userConnectionOccasions.sort((a, b) => a.occasionDate > b.occasionDate) //TODO: Use proper comparison in closure
            setOccasions(userConnectionOccasions);
        } catch (error) {
            console.log(error);
        }
        finally {
            return null;
        }
    }

    function getConnectionId() {
        var url = new URL(window.location.toString())
        let connectionIdNum = Number.parseInt(url.searchParams.get("connectionId")!);
        setConnectionId(connectionIdNum);
    }

    async function getLikedProducts(occasionId, label) {
        try {
            setAnchor(0);
            setCurrentOccasionId(occasionId);
            const likedProductsResponse = await fetch('api/Products/GetUserProducts?anchor=' + 0 + "&connectionId=" + connectionId + '&occasionId=' + occasionId);
            let products = await likedProductsResponse.json()
            setLikedProducts(products)
            setLabel(label);
        } catch (error) {

        }
        finally {
            return null;
        }
    }

    function deleteOccasion(occasion) {
        (async () => {
            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    budget: occasion["budget"],
                    typeOfOccasion: occasion["typeOfOccasion"],
                    connectionId: connectionId,
                    occasionId: occasion["occasionId"],
                    ocassionDate: occasion["date"]
                })
            };
            let respData = await fetch('api/Occasions/RemoveOccasion', settings);
            setOccasions(occasions.filter(occ => occ.occasionId != occasion["occasionId"]))
        })();
    }

    function editOccasionBudget(occasion, budget, date) {
        (async () => {
            const settings = {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    budget: budget,
                    typeOfOccasion: occasion.typeOfOccasion,
                    ocassionDate: new Date(date),
                    occasionId: occasion.occasionId,
                    connectionId: connectionId,
                })
            };
            console.log('coiming here')
            let respData = await fetch('api/Occasions/UpdateOccasion', settings);

            var occasionsCopy = [...occasions]
            var occ: IUserOccasion = occasionsCopy.find(occ => occ.occasionId == occasion.occasionId) as IUserOccasion
            occ.budget = budget
            setOccasions([...occasionsCopy])
        })();
    }

    function onNewOccasions(e) {
        setOccasions(e)
    }

    async function dislikeProduct(product, likeStatus) {
        try {
            product["likeStatus"] = "Disliked"
            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body:
                    JSON.stringify
                        (
                            product,
                        )
            };

            await fetch(`api/Products/UpdateUserProduct?connectionId=` + connectionId, settings)
            hideProduct(product);
        }
        catch (error) {
            console.log("err", error)
        }
    }

    function hideProduct(product) {
        setLikedProducts(likedProducts.filter(prod => prod != product))
    }

    function eventLabel() {
        return (
            label
                ? <h3>Products you have liked for {label}</h3>
                : <h3>Please select event</h3>
        )
    }

    async function loadMoreLikedProducts() {
        let currentAnchor = anchor + 24;
        setAnchor(currentAnchor);
        const likedProductsResponse = await fetch('api/Products/GetUserProducts?anchor=' + currentAnchor + "&connectionId=" + connectionId + '&occasionId=' + currentOccasionId);
        let products = await likedProductsResponse.json()

        setLikedProducts([...likedProducts, ...products])
    }

    useEffect(() => {
        getConnectionId();
        getOccasions();
    }, [])

    return (
        <article className="liked-products">
            <Container fluid >
                <Row>
                    <Col sm={12} md={4} xl={3} className="gift-events-container">
                        <div className="gift-heading">
                            <BaseImage
                                img={gift} />
                            <h3>Gift Events</h3>
                        </div>
                        <section className="gift-events-list">
                            <OccasionEventsListComponent
                                occasions={occasions}
                                onEditedOccasions={(e) => onNewOccasions(e)}
                                onDeleteOccasion={(occ) => deleteOccasion(occ)}
                                onEditOccasionBudget={(occ, budget, date) => editOccasionBudget(occ, budget, date)}
                                onRadioBtnClick={getLikedProducts}
                            />
                        </section>
                    </Col>
                    <Col sm={12} md={8} xl={9} className="products-container">
                        {occasions.length > 0
                            ? eventLabel()
                            : <h3>Please add an event</h3>}

                        <section className="products-list">
                            {
                                likedProducts.length > 0 &&
                                likedProducts.map(product => (
                                    <ProductCard
                                        id={product?.id}
                                        key={product.id}
                                        ribbon={product?.productCategory?.toString() as string}
                                        img={product?.productImages[1] != undefined ? product?.productImages[1]?.imageUrl : product?.productImages[0]?.imageUrl}
                                        img2={product?.productImages[0] != undefined ? product?.productImages[0]?.imageUrl : "/static/media/wise-prezzie-logo.862d5236.svg"}
                                        alt={product.title!}
                                        title={product.title!}
                                        subtitle={product.subtitle!}
                                        brand={product.brand.toString() as string}
                                        price={product.price!}
                                        productUrl={product.productLink}
                                        product={product}
                                        addProductToFavorites={(likeStatus) => dislikeProduct(product, likeStatus)}
                                        hideProduct={(likeStatus) => dislikeProduct(product, 3)}
                                    />
                                ))
                            }
                        </section>
                        {likedProducts.length > 23
                            ? <BaseButton
                                title="Load more"
                                icon=""
                                onClick={loadMoreLikedProducts} />
                            : null}
                    </Col>
                </Row>
            </Container>
        </article>
    )
}

export default LikedProducts;