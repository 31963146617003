import { useState, useEffect, useRef, useContext, useReducer } from 'react'
import './ManageConnections.scss'
import AdminNavbar from "../../components/navbar/AdminNavbar"
import { Col, Container, Row } from 'react-bootstrap'
import BaseFormInput from '../../components/base-components/BaseFormInput'
import PersonCard from '../../components/person-card/PersonCard'
import profileIcon from '../../assets/icons/user-icon.svg'
import addIcon from '../../assets/icons/plus.svg'
import myRecIcon from '../../assets/icons/my-rec.svg'
import AvatarModal from '../../components/modal/modal-components/AvatarModal'
import BaseImage from '../../components/base-components/BaseImage'
import BaseButton from '../../components/base-components/BaseButton'
import giftbox from '../../assets/icons/giftbox2.svg'
import loupe from '../../assets/icons/loupe.svg'
import { IUserConnection } from '../../types/types-internal';
import AddConnectionPopUp from '../../components/modal/modal-add-connection/AddConnectionPopUp'
import { Fade } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import TagManager from 'react-gtm-module'
import WelcomeBackPopUp from '../../components/modal/modal-welcome-back/WelcomeBackModal';
import welcomeImage from '../../assets/svg/welcome.svg';
import connectionsPlaceholder from '../../assets/svg/connections-placeholder.svg';
import GoogleFbLoginMissingDataPopUp from '../../components/modal/modal-google-fb-login-missing-data/GoogleFbLoginMissingDataPopUp'
import { IsNullOrEmpty } from '../../utils/Utils'
import { updateProfile } from '../../components/profile-components/profile-functions/profile-functions';
import EditProfileModal from '../../components/modal/modal-edit-profile/EditProfileModal'
import PlaceholderConnectionPopUp from './PlaceholderConnectionPopUp'
import addUserProfile from '../../assets/icons/add-user-profile.svg'
import { useRecoilState } from 'recoil'
import { userState, selectedConnection } from '../../state/atoms'
import { useHistory } from "react-router-dom";
import BaseObjectDropdown from "../../components/base-components/BaseObjectDropdown";

const ManageConnections = () => {

    const [user, setUser] = useRecoilState(userState)
    const [selectedConnectionState, setSelectedConnectionState] = useRecoilState(selectedConnection);

    const [showProgressBar, setShowProgressBar] = useState(true)
    const [anchor, setAnchor] = useState(0);
    const [sortedConnections, setSortedConnections] = useState(0);
    const [connections, setConnections] = useState<IUserConnection[]>([]);
    const [searchConnectionsQuery, setSearchConnectionsQuery] = useState("");
    const [profileModalShow, setProfileModalShow] = useState(false);
    const [chooseOneConnection, setChooseOneConnection] = useState(false);
    const history = useHistory()

    const tagManagerArgs = {
        dataLayer: {
            userId: user.userId,
            userProject: 'project',
            page: 'manage-connections'
        },
        dataLayerName: 'PageDataLayer'
    }

    TagManager.dataLayer(tagManagerArgs)
    useEffect(() => {
        getConnections(anchor, 0)
    }, []);

    useEffect(() => {
        let accessToConnection = connections.some(x => x.isAccessBlocked === false);
        if (!accessToConnection) {
            setChooseOneConnection(true);
        }
        else if (accessToConnection) {
            setChooseOneConnection(false);
        }
        console.log('disabled is', chooseOneConnection);
    }, [connections])

    async function getConnections(anchor, sort) {
        try {
            const userConnectionsResponse = await fetch('api/Users/GetAllUserConnections?anchor=' + anchor + "&sort=" + sort)
            let userConnections = await userConnectionsResponse.json();
            userConnections.sort((a, b) => a.connectionId > b.connectionId).reverse()
            anchor == 0 ? setConnections(userConnections) : setConnections([...connections, ...userConnections])
            setShowProgressBar(false)
            setAnchor(anchor + 24)

        } catch (error) {
            console.log(error);
        }
        finally {
            return null;
        }
    }

    function removeConnection(connection) {
        if (connection === undefined) {
            return
        }

        fetch("api/Users/RemoveConnection?userConnectionId=" + connection.connectionId, {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
            },
        })
            .then(response => {
                if (response.ok) {
                    let filtered = connections?.filter(conn => conn.connectionId != connection.connectionId)
                    setConnections(filtered)
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    function searchConnections(query) {
        setSearchConnectionsQuery(query)
    }

    function filterConnections(conn) {
        return conn.firstName?.toLowerCase().includes(searchConnectionsQuery.toLowerCase()) ||
            conn.lastName?.toLowerCase().includes(searchConnectionsQuery.toLowerCase()) ||
            (conn.firstName?.toLowerCase() + " " + conn.lastName?.toLowerCase()).includes(searchConnectionsQuery.toLowerCase())
    }

    const goToMyRecommendations = () => {
        setSelectedConnectionState({ connectionId: 0 })
        history.push('/product-recommendations');
    }

    const dropdownSordOptions = [
        {key: "Name (A-Z)", value: 0},
        {key: "Name (Z-A)", value: 1},
        {key: "Date Added (Newest first)", value: 2},
        {key: "Date Added (Oldest first)", value: 3},
        {key: "Upcoming Event", value: 4},
    ]

    function onSortOptionChanged(e) {
        setSortedConnections(e)
        getConnections(0, e);
    }

    return (
        <>
            <AdminNavbar />
            <div hidden={!showProgressBar}>
                <LinearProgress />
            </div>
            <WelcomeBackPopUp
                modalTitle="Thanks for getting back to us"
                icon=""
                user={user}
                img={welcomeImage}
                subtitle="Now you can find impactful gifts while saving you from the hassle."
            />
            <GoogleFbLoginMissingDataPopUp icon="" title="" show={IsNullOrEmpty(user.gender)} />
            {!showProgressBar && <article className="member-connections-page">
                <Fade in={true} timeout={500}>
                    <Container fluid className="profile-container-wrapper">
                        <section className="profile-container">
                            <Container>
                                <Row>
                                    <Col sm={6} className="profile-avatar">
                                        <div >
                                            <EditProfileModal
                                                profile={user}
                                                updateProfile={(profile, image) => updateProfile(profile, image, user, setUser)}
                                                showModal={profileModalShow}
                                                setShowModal={setProfileModalShow} />
                                            <AvatarModal
                                                onProfileClick={setProfileModalShow}
                                                userName={user.userName as string}
                                                icon={user.profilePhotoDownloadUrl || undefined}
                                                alt="Profile image"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                        <Container>
                            <Row className="search-connections-container">
                                <Col sm={12} md={4} lg={6}>
                                    <BaseFormInput
                                        type="search"
                                        name="search"
                                        placeholder="Search relationships"
                                        src={loupe}
                                        onChange={(e) => searchConnections(e)}
                                    />
                                </Col>
                                <Col sm={6} md={4} lg={3}>
                                    <BaseObjectDropdown title="Filter" options={dropdownSordOptions} onClick={(e) => onSortOptionChanged(e)} />
                                </Col>
                                <Col sm={6} md={4} lg={3} className={connections?.length === 0
                                    ? "create-profile no-connections" : "create-profile"}>
                                        <PlaceholderConnectionPopUp
                                            onNewConnection={(e) => getConnections(e, sortedConnections)}
                                            addConnection={() => getConnections(0, sortedConnections)}
                                            title="Add giftee"
                                            icon={addIcon}  
                                        />
                                    <div className="ringring" ></div>
                                </Col>
                                <Col sm={6} md={4} lg={3}>
                                    {/* <BaseButton
                                        onClick={goToMyRecommendations}
                                        title="My Recommendations"
                                        icon={myRecIcon}
                                        alt="">
                                    </BaseButton> */}
                                </Col>
                            </Row>
                            <Row className="persons-container">
                                <Fade in={true} timeout={1000}>
                                    <Container>
                                        {connections?.length == 0
                                            ?
                                            <div className="placeholder-image">
                                                <BaseImage
                                                    img={connectionsPlaceholder}
                                                />
                                                <p className="pt-3">You have no connections here</p>
                                                <p>Add a connection</p>
                                            </div>
                                            :
                                            <>
                                                {chooseOneConnection
                                                    ? <span className="member-error">Members who are not subscribed have only 1 active connection. Chose one from below</span>
                                                    : ""}
                                                {connections!.filter(conn => filterConnections(conn)).length > 0
                                                    ?
                                                    connections?.filter(conn => filterConnections(conn)).map((connection) =>
                                                    (
                                                        <Fade in={true} timeout={600} key={connection.connectionId}>
                                                            <div>
                                                                <PersonCard
                                                                    key={connection.connectionId}
                                                                    personName={connection.firstName + " " + (IsNullOrEmpty(connection.lastName) ? "" : connection.lastName)}
                                                                    personIcon={profileIcon}
                                                                    connectionToEdit={connection}
                                                                    onRemoveConnection={() => removeConnection(connection)}
                                                                    personAlt="Person image"
                                                                    status={connection?.relationship}
                                                                    event=""
                                                                    budget=""
                                                                    buttonIcon={giftbox}
                                                                    disabled={connection?.isAccessBlocked}
                                                                    buttonAlt="giftbox"
                                                                    showChoseButton={chooseOneConnection}
                                                                    dropdownTitle="Other events" />
                                                            </div>
                                                        </Fade>
                                                    ))
                                                    :
                                                    <div className="placeholder-image">
                                                        <BaseImage
                                                            img={connectionsPlaceholder}
                                                        />
                                                        <p className="pt-3">You have no connections here</p>
                                                        <p>Add a connection</p>
                                                    </div>}
                                            </>
                                        }

                                    </Container>
                                </Fade>
                            </Row>
                        </Container>
                    </Container>
                </Fade>
                {
                    connections!.length > 23
                        ? <BaseButton
                            title="Load more"
                            icon=""
                            onClick={() => getConnections(anchor, sortedConnections)} />
                        : null
                }
            </article>}
        </>
    )
}

export default ManageConnections;