import './Statistics.scss';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Row, Table } from 'react-bootstrap';
import { IProductStatisticsModel, IUserStatisticsFeedbackModel, IUserStatisticsModel } from '../../types/types-internal';
import { SubscriptionType } from '../../enums/UserEnums';
import axios from 'axios';

const Statistics = () => {
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [productStatistics, setProductStatistics] = useState<IProductStatisticsModel[]>();
    const [userStatistics, setUserStatistics] = useState<IUserStatisticsModel[]>();
    const [rowIndexes, setRowIndexes] = useState<number[]>([]);
    const [userFeedback, setUserFeedback] = useState<IUserStatisticsFeedbackModel[]>();
    const [adminSubscription, setAdminSubscription] = useState({});
    useEffect(() => {
        (async () => {
            let result = await fetch('api/Users/UserProductStatistics');
            if (result.status == 200) {
                let response = await result.json();
                setProductStatistics(response);
                setIsAuthorized(true);
            }
        })();

        (async () => {
            let result = await fetch('api/Users/UserStatistics');
            if (result.status == 200) {
                let response = await result.json();
                setUserStatistics(response);
                setIsAuthorized(true);
            }
        })();

        (async () => {
            let result = await fetch('api/Users/GetAllUserFeedback');
            if (result.status == 200) {
                let response = await result.json();
                setUserFeedback(response);
                setIsAuthorized(true);
            }
        })();

    }, [])

    function sortUsersPerCreatedDate(a: IUserStatisticsModel, b: IUserStatisticsModel) {
        return +new Date(b.createdOn) - +new Date(a.createdOn);
    }

    let toggleDetails = (index) => {
        //togle
        if (!rowIndexes.find(x => x === index)) {
            setRowIndexes([...rowIndexes, index]);
        }
        else {
            setRowIndexes(rowIndexes.filter(x => x != index));
        }
    }

    let totalProducts = productStatistics?.reduce((prevValue, currentValue) =>
        ({ productSource: 'TOTAL_PRODUCTS', availableCount: prevValue.availableCount + currentValue.availableCount, soldOutCount: prevValue.soldOutCount + currentValue.soldOutCount }))


    const setSubscriptionType = (e: any) => {
        setAdminSubscription({ ...adminSubscription, subscriptionType: e.target.value })
    }
    const setSubscriptionEmail = (e: any) => {
        setAdminSubscription({ ...adminSubscription, email: e.target.value })
    }

    const sendSubsrcription = async () =>{
        console.log('subscription is', adminSubscription)
        try{
            let result = await axios.post('api/payment/AdminUpdateSubscription', adminSubscription);
            if(result.status == 200){
                alert(`Success updating the subscription type!`);
            }
        }catch(err){
            alert(`Something went wrong updating the subscription status.`);
        }
    }

    return (
        isAuthorized
            ? <Container>
                <Row className="subscription-editing">
                    <h4>Admin Subscription editing</h4>
                    <Col md={4}>
                        <div >Provide the user who's subscription you will be editing</div>
                        <input type="text" onChange={setSubscriptionEmail} placeholder="User Email" />
                    </Col>
                    <Col md={4}>
                        <div >Set the level of subscription</div>
                        <select onChange={setSubscriptionType}>
                            {Object.keys(SubscriptionType).map(key => (
                                <option key={key} value={key}>
                                    {SubscriptionType[key]}
                                </option>
                            ))}
                        </select>
                    </Col>
                    <Col md={2}>
                        <Button variant="success" onClick={sendSubsrcription}>UPDATE</Button>
                    </Col>
                </Row>
                <br /> <br />
                <Row>
                    <h4>Products statistics</h4>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Product Source</th>
                                <th>Available Products</th>
                                <th>Sold Out Products</th>
                            </tr>
                        </thead>
                        <tbody>
                            {productStatistics && productStatistics.length > 0 && productStatistics.map((p, index) => (
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{p.productSource}</td>
                                    <td>{p.availableCount}</td>
                                    <td>{p.soldOutCount}</td>
                                </tr>
                            ))}
                            {
                                <tr>
                                    <td></td>
                                    <td>{totalProducts?.productSource}</td>
                                    <td>{totalProducts?.availableCount}</td>
                                    <td>{totalProducts?.soldOutCount}</td>
                                </tr>}
                        </tbody>
                    </Table>
                </Row >
                <br /><br />
                <Row>
                    <h4>Users statistics</h4>
                    <Table striped bordered hover>
                        <thead className='user-statistics-head'>
                            <tr>
                                <th>#</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Gender</th>
                                <th>Created On</th>
                                <th>Liked Products</th>
                                <th>Disliked Products</th>
                                <th>Products shown in 24H</th>
                                <th>Occasions Count</th>
                                <th>Real Relationships</th>
                                <th>Placeholder Relationships</th>
                                <th>Last Active</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userStatistics && userStatistics.length > 0 && userStatistics.sort(sortUsersPerCreatedDate).map((u, index) => (
                                <>
                                    <tr onClick={() => toggleDetails(index + 1)} key={index}>
                                        <td>{index + 1}</td>
                                        <td>{u.firstName}</td>
                                        <td>{u.lastName}</td>
                                        <td>{u.email}</td>
                                        <td>{u.gender}</td>
                                        <td>{new Date(u.createdOn).toLocaleString()}</td>
                                        <td>{u.likedProductsCount}</td>
                                        <td>{u.dislikedProductsCount}</td>
                                        <td>{u.productsShownLast24HCount}</td>
                                        <td>{u.occasionsCount}</td>
                                        <td>{u.realConnectionsCount}</td>
                                        <td>{u.placeholderConnectionsCount}</td>
                                        <td>{u.lastActive ? new Date(u.lastActive).toLocaleString() : null}</td>
                                    </tr>
                                    {/* {rowIndexes.find(x => x === index + 1) ?
                                        <tr>
                                            <td colSpan={12}>
                                                <StatisticDetails />
                                            </td>
                                        </tr>
                                        : null} */}

                                </>
                            ))}
                        </tbody>
                    </Table>
                </Row>
                <br /><br />
                <Row>
                    <h4>Users Feedback</h4>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Feedback</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userFeedback && userFeedback.length > 0 && userFeedback.map((x, index) => (
                                <>
                                    <tr>
                                        <td>
                                            {index + 1}
                                        </td>
                                        <td>
                                            {x.firstName}
                                        </td>
                                        <td>
                                            {x.lastName}
                                        </td>
                                        <td>
                                            {x.email}
                                        </td>
                                        <td>
                                            {x.feedbacks.sort((a, b) => +new Date(b.createdOn) - +new Date(a.createdOn)).map((y, index) => (
                                                <>
                                                    <tr>
                                                        <strong>{y.feedbackStatus} </strong> | {y.text} | {new Date(y.createdOn).toLocaleString()}
                                                    </tr>
                                                    {index < x.feedbacks.length - 1 ? <span>---------</span> : null}
                                                </>
                                            ))}
                                        </td>
                                    </tr>
                                </>
                            ))}
                        </tbody>
                    </Table>
                </Row>
            </Container >
            : null
    )
};

export default Statistics;