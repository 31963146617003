import React, { FunctionComponent, useEffect } from "react";
import { Modal, Button } from 'react-bootstrap';
import './SetPasswordModal.scss';
import ResetPasswordContainer from './ResetPasswordContainer';
import { encode } from "querystring";

interface Props {
    show: boolean,
    onHide: any,
    onClick?: any,
    forgotPassword?: { email: string, token: string }
}

function SetPasswordModal(props: Props) {

    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ResetPasswordContainer onHide={props.onHide} forgotPassword={props.forgotPassword} />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

type ButtonProps = {
    title: string,
    icon: string,
    onClick?: any,
}

const App: FunctionComponent<ButtonProps> = (props) => {

    const [modalShow, setModalShow] = React.useState(false);
    const [forgotPassword, setForgotPassword] = React.useState({ email: '', token: '' })

    useEffect(() => {
        var url = new URL(window.location.toString())
        let email = url.searchParams.get("email")
        let token = url.search?.split('&token=')[1];
        console.log('token is ' + token)
        if (email != null && token != null) {
            setForgotPassword({ email: email, token: token });
            setModalShow(true)
        }
    }, []);


    return (
        <>
            <SetPasswordModal
                forgotPassword={forgotPassword}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
}

export default App;