import React, { FunctionComponent, useState, useEffect } from "react";
import { Modal, Button } from 'react-bootstrap';
import './LoginField.scss';
import BaseButton from '../base-components/BaseButton';
import LoginForm from './LoginForm';
import {IsNullOrEmpty} from '../../utils/Utils';

interface Props {
    show: boolean,
    onHide: any,
}

function LoginPopUp(props: Props) {

    const [modalHidden, setModalHidden] = useState(false)

    return (
        <Modal
            {...props}
            className={!modalHidden ? " login-modal active" : "login-modal"}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LoginForm closeModal = {props.onHide} hideModal={(e) => setModalHidden(e)} />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

type ButtonProps = {
    title: string,
    icon: string,
}

const LoginField: FunctionComponent<ButtonProps> = ({ title, icon }) => {

    const [modalShow, setModalShow] = React.useState(false);

    useEffect(() => 
    {
        var url = new URL(window.location.toString())
        if(url.toString().includes("successful-registration")){
       
            let email = url.searchParams.get("email")

            if(!IsNullOrEmpty(email))
            {
                setModalShow(true)   
            }
        }
    }, []);

    return (
        <>
            <BaseButton
                onClick={() => setModalShow(true)}
                title={title}
                icon={icon}
                alt="Right Arrow">
            </BaseButton>
            <LoginPopUp
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
}



export default LoginField;