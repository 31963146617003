import React, { FunctionComponent, useState } from "react";
import './BaseCheckbox.scss';

type ChechboxProps = {
    label: string
    chackboxId?: string
    chackboxFor?: string,
    value?: boolean,
    onChange?: Function
}

const BaseCheckbox: FunctionComponent<ChechboxProps> = ({ label, value, onChange }) => {

    const onValueChange = () =>{
        if(onChange){
            onChange();
        }
    }
    return (
        <>
            <label className="checkbox-container">
                {label}
                <input 
                type="checkbox"
                checked={value}
                onChange={() => onValueChange()} />
                <span className="checkmark"></span>
            </label>
        </>
    )
}

export default BaseCheckbox;