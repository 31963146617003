import { Fade } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Gender } from '../../../enums/Gender';
import PlaceholderConnectionPopUp from '../../../pages/manage-connections/PlaceholderConnectionPopUp';
import BaseButton from '../../base-components/BaseButton';
import './ExternalConnectionsList.scss';

const ExternalConnectionsList = (props) => {

    let createExternalConnectionProfile = (contact) => {
        let firstName = contact.givenName;
        if (!contact.givenName && !contact.familyName) {
            firstName = contact.email;
        }
        console.log('coming through')
        fetch("api/Users/AddPlaceholderUserConnection", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
            },
            "body": JSON.stringify({
                firstName: firstName,
                lastName: contact.familyName,
                connectionEmail: contact.email,
                gender: Gender.NONBINARY,
                birthday: null,
                ageRange: null,
                country: null,
                stateTerritory: null,
                city: null,
                relationship: null
            })
        })
            .then(response => {
                let code = response.status
                props.showSuccessAlerts(code)
                props.addConnection(response.json())
            })
            .catch(err => {
                console.log(err);
            });
    }

    return (
        <div className="external-contacts-container">
            <h4>Select to import contact</h4>
            {props.externalContacts.map(contact => (
                <Row className="person-search-result-container">
                    <Col xs={9} className="person-info-container person-image-group">
                        <section className="person-info">
                            <div>
                                <h3> {contact.givenName} {contact.familyName}</h3>
                                <p> {contact.email} </p>
                            </div>
                        </section>
                    </Col>
                    <Col xs={3}>
                        <BaseButton
                            title="Add"
                            onClick={() => createExternalConnectionProfile(contact)}
                            icon='' />
                    </Col>
                </Row>
            ))}
        </div>
    )
}

export default ExternalConnectionsList;