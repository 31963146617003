import { useState } from "react";
import { Col } from 'react-bootstrap';
import './SendEmailModal.scss';
import BaseButton from '../../base-components/BaseButton';
import HeaderModal from '../modal-components/HeaderModal';
import BaseFormInput from '../../base-components/BaseFormInput';
import Alert from '@material-ui/lab/Alert';
import { Fade } from '@material-ui/core';

const SendEmailModal = (props) => {

    const [email, setEmail] = useState("")
    const [emailRequired, setEmailRequired] = useState(false)
    const [showButtonLoading, setShowButtonLoading] = useState(false)
    const [showSuccessAlert, setShowSuccessAlert] = useState(false)
    const [inviteError, setInviteError] = useState(false)

    function onChange(e)
    {
        setEmail(e)
    }

    function inviteViaEmail()
    {
        console.log(email)
        setShowButtonLoading(true)

        if(email != "")
        {
            if(/^[a-zA-Z0-9-_.]+@[a-zA-Z0-9-.]+\.[A-Za-z]+$/.test(email))
            {
                setEmailRequired(false)
                sendEmail(email)
            }
            else
            {
                setEmailRequired(true)
            }
        }
        else
        {
            if(/^[a-zA-Z0-9-_.]+@[a-zA-Z0-9-.]+\.[A-Za-z]+$/.test(props.user.connectionEmail))
            {
                setEmailRequired(false)
                sendEmail(props.user.connectionEmail)
            }
            else
            {
                setEmailRequired(true)
            }
        }

        
        setTimeout(() => {
            setShowButtonLoading(false)
        }, 400)

    }

    async function sendEmail(email)
    {
        try
        {
            const inviteResponse = await fetch('api/Auth/GenerateEmailToRegisterPlaceholderConnection?email=' + email)

            if(inviteResponse.status == 200){
                setShowSuccessAlert(true)
            }else{
                setInviteError(true)
            }

            setShowSuccessAlert(true)
                
            setTimeout(() => {
                setShowSuccessAlert(false)
            }, 3000)
            setTimeout(() => {
                setInviteError(false)
            }, 3550)
        } 
        catch (error) 
        {
            console.log("err",error)
        }      
    }
 
    return (
        <section className="send-email-modal active">
             <Fade in={showSuccessAlert} timeout={500}>
                {
                    !inviteError ? 
                    <Alert severity="success" color="success" >
                        Invitation successful!
                    </Alert> :
                    <Alert severity="error" color="error" >
                        User with email: "{email}" already invited!
                    </Alert> 
                }
            </Fade>
            <HeaderModal
                heading={props.title}
                span=""
                paragraph=""
            />
            <Col xl={12} className="search-container mb-3">
                 <BaseFormInput
                    onChange={(e) => onChange(e)}
                    type="text"
                    name="sendEmail"
                    placeholder={props.user.connectionEmail || "Email  *"} />
                    <span className="required-modal-field" hidden={!emailRequired}>Please enter a valid email</span>
            </Col>
            <BaseButton
                className="mb-3"
                onClick={() => inviteViaEmail()}
                title="Invite via email"
                showSpinner={showButtonLoading}
                icon=""
                alt="" 
            />
        </section>
    )
}

export default SendEmailModal;
