import React, { FunctionComponent, useState } from "react";
import BaseImage from '../../base-components/BaseImage'
import checkItem from "../../../assets/icons/checking-mark.svg"
import './SearchItemModal.scss';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IUserConnection, IUserHoliday } from "../../../types/types-internal";
import { DateUtils } from '../../../utils/DateUtils';

type SearchItemProps = {
    label?: string,
    img?: string,
    alt?: string,
    active?: boolean,
    activeInterest?: boolean
    onClick?: any,
    occasionHoliday?: IUserHoliday,
    onDateChange?: any
    connection?: IUserConnection,
    added?: boolean
}

const SearchItemModal: FunctionComponent<SearchItemProps> = ({ label, img, alt, active, onClick, occasionHoliday, onDateChange, connection, added, activeInterest }) => {

    const [startDate, setStartDate] = useState(occasionHoliday?.ocassionDate);
    let onDateSelected = (date) => {
        console.log('date and event ', date)
        setStartDate(date);
        if (onDateChange) onDateChange(date);
    }

    let holidayClicked = () => {
        console.log('anything  ? ', onClick)
        if (onClick) onClick();
    }

    return (
        <>
            {added
                ? <div className={`search-item-container item-added`}>
                    <span className="event-exists-span">Event already exists</span>
                    <div className="search-item-box">
                        <div className="item-check">
                            <BaseImage
                                img={checkItem}
                                alt="Checking item" />
                        </div>
                        <BaseImage
                            img={img}
                            alt={alt} />
                    </div>
                    <span>{label}</span>
                </div>
                : <div onClick={() => holidayClicked()} className={`search-item-container ${(active || activeInterest) ? "selected" : ""}`}>
                    <div className="search-item-box">
                        <div className="item-check">
                            <BaseImage
                                img={checkItem}
                                alt="Checking item" />
                        </div>
                        <BaseImage
                            img={img}
                            alt={alt} />
                    </div>
                    <span>{label}</span>
                </div>}
            {
                active && !added &&
                <DatePicker
                    className="data-picker"
                    selected={DateUtils.utcToLocal(startDate)}
                    onChange={(d) => onDateSelected(DateUtils.localToUtc(d))}
                />
            }
        </>
    )
}

export default SearchItemModal;
