import { FunctionComponent, useState, useEffect } from "react";
import './ProductCard.scss';
import BaseButton from "../base-components/BaseButton";
import BaseImage from "../base-components/BaseImage";
import bag from "../../assets/icons/bag.svg";
import heart from "../../assets/icons/heart.svg";
import cancel from "../../assets/icons/cancel.svg";
import { IProduct } from "../../types/types-internal";
import { LikeStatus } from "../../enums/LikeStatus";
import { formatPrice } from '../../utils/CurrencyUtils';

type ProductCardProps = {
    id?: number,
    img: string,
    img2?: string,
    alt: string,
    title: String,
    subtitle: string,
    brand: string,
    price: string | number,
    ribbon: string,
    productUrl?: string,
    product: IProduct,
    addProductToFavorites?: any,
    hideProduct?: any,
}

const ProductCard: FunctionComponent<ProductCardProps> = ({ id, img, img2, alt, title, brand, price, ribbon, productUrl, product, addProductToFavorites, hideProduct, subtitle }) => {

    const [hidden, setHidden] = useState(true);
    const [icon, setIcon] = useState(LikeStatus[product!.likeStatus!] == LikeStatus.Liked.toString() ? heart : "");
    const [showSpinner, setShowSpinner] = useState(false)

    useEffect(() => {
        setIcon(LikeStatus[product!.likeStatus!] == LikeStatus.Liked.toString() ? heart : "")
    }, [product]);



    function notInterestedInProduct() {
        addProductToFavorites(LikeStatus.Disliked);
        hideProduct();
    }

    function setHeartIcon() {
        if (!hidden) {
            setHidden(true)
        }
        addProductToFavorites(icon == heart ? LikeStatus.Disliked : LikeStatus.Liked)
        setIcon(LikeStatus[product.likeStatus!] == LikeStatus.Liked.toString() ? heart : "")
    }

    function visitProductPage() {
        setShowSpinner(true)
        setTimeout(() => {
            setShowSpinner(false)
            window.open(productUrl)
        }, 500)
    }

    return (
        <div className={"product-card-container"}>
            <div className="ribbon">
                {ribbon}
            </div>
            <BaseImage
                img={!img.startsWith("https://") ? "https://" + img : img}
                img2={!img2!.startsWith("https://") ? "https://" + img2 : img2}
                alt={alt} />
            <h5>{title}</h5>
            <p>{subtitle}</p>
            <span>from: {formatPrice(price)}</span>
            <BaseButton
                className="buy-now-button"
                showSpinner={showSpinner}
                onClick={() => visitProductPage()}
                title="Buy now"
                icon={bag}
                alt="Buy" />
            <div className="option-buttons-group">
                <BaseButton
                    className="zIndex9"
                    onClick={() => setHeartIcon()}
                    title="Love It!"
                    icon={icon}
                    alt={icon ? "Love It!" : ""} />
                <BaseButton
                    onClick={() => notInterestedInProduct()}
                    title="No thanks"
                    icon={cancel}
                    alt="No thanks" />
            </div>
        </div>
    )
}

export default ProductCard;
