import React, { useState, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import './Main.scss';
import Modal from '../modal/PopUp';
import arrowRight from '../../assets/icons/right-arrow.svg';
import { Fade, Grow, Slide } from '@material-ui/core';
import {IsNullOrEmpty} from '../../utils/Utils';

const MainForm = () => {

    const initialValues = { email: "", password: "", firstName: "", lastName: "", isRealUser: true };

    const [values, setValues] = React.useState(initialValues);

    const [errors, setErrors] = React.useState({});

    const [touched, setTouched] = React.useState({});

    // change event handler
    const handleChange = evt => {
        const { name, value: newValue, type } = evt.target;

        // keep number fields as numbers
        const value = type === "number" ? +newValue : newValue;

        // save field values
        setValues({
            ...values,
            [name]: value
        });

        // was the field modified
        setTouched({
            ...touched,
            [name]: true
        });
    };

    const handleBlur = evt => {
        const { name, value } = evt.target;

        // remove whatever error was there previously
        const { [name]: removedError, ...rest } = errors;

        // check for a new error
        const error = validate[name](value);

        // // validate the field if the value has been touched
        setErrors({
            ...rest,
            ...(error && { [name]: touched[name] && error })
        });
    };

    // form submit handler
    const handleSubmit = evt => {
        evt.preventDefault();
    };

    const nameValidation = (fieldName, fieldValue) => {
        if (fieldValue.trim() === "") {
            return `${fieldName} is required`;
        }

        return null;
    };

    const emailValidation = email => {
        if (
            /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                email
            )
        ) {
            return null;
        }
        if (email.trim() === "") {
            return "Email is required";
        }
        return "Please enter a valid email";
    };

    const ageValidation = age => {
        if (!age) {
            return "Age is required";
        }
        if (age < 18) {
            return "Age must be at least 18";
        }
        if (age > 99) {
            return "Age must be under 99";
        }
        return null;
    };

    const validate = {
        firstName: name => nameValidation(values.firstName, name),
        lastName: name => nameValidation(values.lastName, name),
        email: emailValidation,
        age: ageValidation
    };


    function validateValues() 
    {
       if(values.firstName === "" || values.lastName === "" || values.email === "")
       {
            return false
       }
       else
       {  
            if (!/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                    values.email
                )
            ) {
                return false
            }
           
           return true
       }
    };

    useEffect(() => 
    {
        var url = new URL(window.location.toString())
        if(url.toString().includes("invite-connection")){
       
            let email = url.searchParams.get("email")

            if(!IsNullOrEmpty(email))
            {
                var emailField = document.getElementById("emailInputField")
                emailField.value = email
                emailField.focus()
               
                values.email = email
            }
        }
    }, []);

    return (
        <Grow in={true} timeout={500}>
        <form onSubmit={handleSubmit}>
            <Row>
                <Col sm={12} md={6}>

                    <label className="error-message-text-red">
                        <input 
                            inputMode="text"
                            type="text" 
                            name="firstName"
                            placeholder="First name *"
                            value={values.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required />

                        {touched.firstName && errors.firstName}
                      
                    </label>
                   

                </Col>
                <Col sm={12} md={6}>
                    <label className="error-message-text-red">
                        <input 
                            type="text" 
                            name="lastName"
                            placeholder="Last name *"
                            value={values.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="lastName"
                            required />

                        {touched.lastName && errors.lastName}
                    </label>
                </Col>
            </Row>
            <Row>
                <Col>
                    <label className="error-message-text-red">
                        <input 
                            id="emailInputField"
                            type="email" 
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Email Address *"
                            required
                        />
                        {touched.email && errors.email}
                    </label>
                </Col>
            </Row>
            <Row>
                <Col className="d-flex justify-content-center submit">
                   <Modal
                   className="pulse-button"
                        title="Give Wiseprezzie a Try"
                        icon={arrowRight}
                        values={values} 
                        disabled={validateValues()}
                    /> 
                </Col>
            </Row>
        </form>
        </Grow>
    )
}

export default MainForm;