import React, { FunctionComponent, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import BaseButton from "../../base-components/BaseButton"
import './SaveCancelButtons.scss';

type Props = {
    saveClick: any,
    cancelClick: any,
}

const SaveCancelButtons: FunctionComponent<Props> = ({ saveClick, cancelClick }) => {
    
        const [showSpinner, setShowSpinner] = useState(false)

        function save()
        { 
            setShowSpinner(true)
            setTimeout(() => {
                setShowSpinner(false)
                saveClick()
            }, 500)

        }

        return (
            <Container className="buttons-container">
                <Row>
                    <Col xs={12} sm={6}>
                        <BaseButton
                            showSpinner={showSpinner}
                            onClick={() => save()}
                            title="Save"
                            icon=""
                            alt="">
                        </BaseButton>
                    </Col>
                    <Col xs={12} sm={6} className="cancel">
                        <BaseButton
                            onClick={cancelClick}
                            title="Cancel"
                            icon=""
                            alt="">
                        </BaseButton>
                    </Col>
                </Row>
            </Container>
        )
    }

    export default SaveCancelButtons;