import React from "react";
import './Footer.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SocialIcon from '../base-components/SocialIcon';
import facebook from '../../assets/icons/facebook.svg';
import twitter from '../../assets/icons/twitter.svg';
import linkedin from '../../assets/icons/linkedin.svg';
import instagram from '../../assets/icons/instagram.svg';
import youtube from '../../assets/icons/youtube.svg';


const Footer = () => {
    return (
        <footer>
            <Container>
                <Row>
                    <Col>
                        <div className="social-icons-container">
                            <SocialIcon
                                icon={facebook}
                                alt="Facefook"
                                href="https://www.facebook.com/WisePrezzie"
                                target="_blank"
                                rel="noopener" />
                            <SocialIcon
                                icon={twitter}
                                alt="Twitter"
                                href="https://twitter.com/wiseprezzie"
                                target="_blank"
                                rel="noopener" />
                            <SocialIcon
                                icon={instagram}
                                alt="Instagram"
                                href="https://www.instagram.com/wise.prezzie/?hl=en"
                                target="_blank"
                                rel="noopener" />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>© {new Date().getUTCFullYear()} WisePrezzie, Inc. All rights reserved.</p>
                    </Col>
                </Row>
                <Row className="footer-info">
                    <Col className="d-flex justify-content-center">
                        <Link to={'/about'}>About</Link>
                    </Col>
                    <Col className="d-flex justify-content-center">
                        <Link to={'/terms-conditions'}>Terms & conditions</Link>
                    </Col>
                    <Col className="d-flex justify-content-center">
                        <Link to={'/privacy-policy'}>Privacy policy</Link>
                    </Col>
                    {/* <Col className="d-flex justify-content-center">
                        <Link to={'/cookies-policy'}>Cookies policy</Link>
                    </Col> */}
                    <Col className="d-flex justify-content-center">
                        <Link to={'/ccpa-policy'}>CCPA policy</Link>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer;