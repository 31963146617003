import React, { useContext } from 'react'
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import './ErrorPage.scss'
import BaseImage from '../../components/base-image/BaseImage'
import BaseButton from '../../components/base-components/BaseButton';
import errorPage from '../../assets/images-lg/error-page.png'
import arrow from '../../assets/icons/right-arrow.svg'
import {  useRecoilValue } from 'recoil'
import { userState } from '../../state/atoms';

const ErrorPage = () => {
    const user = useRecoilValue(userState);
    let history = useHistory();

    function handleClick() {
        history.push(user.isLoggedIn ? '/relationships' : '/');
    }
    return (
        <>
            <article className="error-page-container">
                <Container>
                    <Row>
                        <Col>
                            <h1>Page Not Found</h1>
                            <p>The page you were looking for could not be found. It might have been removed, renamed, or did not exist in the first place.</p>
                            <BaseImage
                                img={errorPage}
                                alt="Error Page" />
                            <BaseButton
                                className="arrow-animation"
                                title="go to home page"
                                icon={arrow}
                                onClick={() => handleClick()}>
                            </BaseButton>
                        </Col>
                    </Row>
                </Container>
            </article>
        </>
    )
}

export default ErrorPage;
