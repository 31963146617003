import React, { useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import './Main.scss';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import DesignPicture from '../design-picture/DesignPicture';
import DesignElement from '../design-element/DesignElement';
import bgImage1 from '../../assets/images/images-sm/bg-image-1-sm.jpg';
import bgImage2 from '../../assets/images/images-sm/bg-image-2-sm.jpg';
import bgImage3 from '../../assets/images/images-sm/bg-image-3-sm.jpg';
import bgImage4 from '../../assets/images/images-sm/bg-image-4-sm.jpg';
import bgImage5 from '../../assets/images/images-sm/bg-image-5-sm.jpg';
import bgImage6 from '../../assets/images/images-sm/bg-image-6-sm.jpg';
import bgImage7 from '../../assets/images/images-sm/bg-image-7-sm.jpg';
import element1 from '../../assets/svg//element1.svg';
import element2 from '../../assets/svg//element2.svg';
import element3 from '../../assets/svg//element3.svg';
import element4 from '../../assets/svg//element4.svg';
import element5 from '../../assets/svg//element5.svg';
import element6 from '../../assets/svg//element6.svg';
import element7 from '../../assets/svg//element7.svg';
import element8 from '../../assets/svg//element8.svg';
import element9 from '../../assets/svg//element9.svg';
import { Fade, Grow, Slide } from '@material-ui/core';
import MainForm from "./MainForm";
import SetPasswordModal from "../../components/modal/modal-reset-password/SetPasswordModal";

const Main = () => {
    return (
        <Fade in={true} timeout={300}>
            <main>
                <div id="container-main" ></div>
                <SetPasswordModal title="Set new Password" icon="" />
                <Container fluid className="main-container">
                    <Row>
                        <Col md={12} lg={2} xl={3} className="decor">
                            <Grow in={true} timeout={800}>
                                <div className="picture-container first">
                                    <ScrollAnimation animateIn='animate__fadeInDown'>
                                        <DesignPicture
                                            imageSrc={bgImage1}
                                            alt="image" />
                                        <DesignElement
                                            imageSrc={element1}
                                            alt="element" />
                                    </ScrollAnimation>
                                </div>
                            </Grow>
                            <Grow in={true} timeout={1300}>
                                <div className="picture-container secound">
                                    <ScrollAnimation animateIn='animate__fadeInLeft'>
                                        <DesignPicture
                                            imageSrc={bgImage2}
                                            alt="image" />
                                        <DesignElement
                                            imageSrc={element4}
                                            alt="element" />
                                        <DesignElement
                                            imageSrc={element3}
                                            alt="element" />
                                    </ScrollAnimation>
                                </div>
                            </Grow>
                            <Grow in={true} timeout={1600}>
                                <div className="picture-container third">
                                    <ScrollAnimation animateIn='animate__fadeInLeft'>
                                        <DesignPicture
                                            imageSrc={bgImage3}
                                            alt="image" />
                                        <DesignElement
                                            imageSrc={element2}
                                            alt="element" />
                                    </ScrollAnimation>
                                </div>
                            </Grow>
                        </Col>
                        <Col className="d-flex justify-content-center">
                            <section className="main-content">
                                <Container>
                                    <Row>
                                        <Col>
                                            <h1>Great gifting.<br></br>Made simpler.</h1>
                                            <p>Finding great gifts can be difficult and time-consuming. WisePrezzie simplifies your search by helping you find gifts for any recipient and any occasion without the hassle. </p>
                                        </Col>
                                    </Row>
                                </Container>
                                <Container className="form-container">
                                    <MainForm />
                                </Container>
                            </section>
                        </Col>
                        <Col md={12} lg={2} xl={3} className="decor">
                            <Grow in={true} timeout={800}>
                                <div className="picture-container fourth">
                                    <ScrollAnimation animateIn='animate__fadeInDown'>
                                        <DesignPicture
                                            imageSrc={bgImage4}
                                            alt="image" />
                                        <DesignElement
                                            imageSrc={element5}
                                            alt="element" />
                                        <DesignElement
                                            imageSrc={element9}
                                            alt="element" />
                                    </ScrollAnimation>
                                </div>
                            </Grow>
                            <Grow in={true} timeout={1200}>
                                <div className="picture-container fifth">
                                    <ScrollAnimation animateIn='animate__fadeInRight'>
                                        <DesignPicture
                                            imageSrc={bgImage5}
                                            alt="image" />
                                        <DesignElement
                                            imageSrc={element6}
                                            alt="element" />
                                    </ScrollAnimation>
                                </div>
                            </Grow>
                            <Grow in={true} timeout={1600}>
                                <div className="picture-container sixth">
                                    <ScrollAnimation animateIn='animate__fadeInRight'>
                                        <DesignPicture
                                            imageSrc={bgImage6}
                                            alt="image" />
                                        <DesignElement
                                            imageSrc={element7}
                                            alt="element" />
                                    </ScrollAnimation>
                                </div>
                            </Grow>
                            <Grow in={true} timeout={1900}>
                                <div className="picture-container seventh">
                                    <ScrollAnimation animateIn='animate__fadeInRight'>
                                        <DesignPicture
                                            imageSrc={bgImage7}
                                            alt="image" />
                                        <DesignElement
                                            imageSrc={element8}
                                            alt="element" />
                                    </ScrollAnimation>
                                </div>
                            </Grow>
                        </Col>
                    </Row>
                </Container>
            </main>
        </Fade>

    )
}


export default Main;