import React, { FunctionComponent, useContext, useEffect, useState } from 'react'
import './OccasionComponent.scss'
import { Col, Container, Row } from 'react-bootstrap'
import EventItemModal from '../modal/modal-components/EventItemModal';
import EditGiftEventsPopUp from '../../components/modal/modal-gift-events/EditGiftEventsPopUp'

import eventsPlaceholder from '../../assets/svg/events-placeholder.svg';
import editIcon from '../../assets/icons/edit.svg'
import event1 from '../../assets/images/event1.jpg'
import event2 from '../../assets/images/event2.jpg'
import event3 from '../../assets/images/event3.jpg'
import event4 from '../../assets/images/event4.jpg'
import event5 from '../../assets/images/event5.jpg'
import event6 from '../../assets/images/event6.jpg'
import event7 from '../../assets/images/event7.jpg'
import event8 from '../../assets/images/event8.jpg'
import event9 from '../../assets/images/event9.jpg'
import event10 from '../../assets/images/event10.jpg'
import event11 from '../../assets/images/event11.jpg'
import event14 from '../../assets/images/event14.png'
import birthdayImg from '../../assets/images/birthday.jpg'
import thanksgivvingImg from '../../assets/images/thanksgiving.jpg'
import xmasImg from '../../assets/images/xmas.jpg'
import newyearImg from '../../assets/images/newyear.jpg'
import justbecauseImg from '../../assets/images/just.jpg'
import { IUserConnection, IUserOccasion } from '../../types/types-internal';

type OccasionProps = {
    occasions?: any,
    connection?: IUserConnection,
    isCurrentUser?: boolean,
    onEditedOccasions?: any,
    onDeleteOccasion?: any,
    onEditOccasionBudget?: any,
}

const OccasionComponent: FunctionComponent<OccasionProps> = (props) => {

    const [occasions, setOccasions] = useState<IUserOccasion[]>([])

    useEffect(() => {
        setOccasions(props.occasions)
    }, [props.occasions])

    function formatDate(occasionDate) {
        if (occasionDate) {
            return new Date(occasionDate).toISOString()
        }
        return new Date().toISOString();
    }

    function getImage(occasion) {
        var image = xmasImg
        if (occasion.typeOfOccasion === "Christmas") {
            image = xmasImg
        }
        else if (occasion.typeOfOccasion === "Thanksgiving") {
            image = thanksgivvingImg
        }
        else if (occasion.typeOfOccasion === "NewYearsDay") {
            image = newyearImg
        }
        else if (occasion.typeOfOccasion === "Birthday") {
            image = birthdayImg
        }
        else if (occasion.typeOfOccasion === "JustBecause") {
            image = justbecauseImg
        }
        else if (occasion.typeOfOccasion === "ValentinesDay") {
            image = event2
        }
        else if (occasion.typeOfOccasion === "Easter") {
            image = event3
        }
        else if (occasion.typeOfOccasion === "Hannukah") {
            image = event9
        }
        else if (occasion.typeOfOccasion === "Kwanzaa") {
            image = event11
        }
        else if (occasion.typeOfOccasion === "MothersDay") {
            image = event4
        }
        else if (occasion.typeOfOccasion === "FathersDay") {
            image = event5
        }
        else if (occasion.typeOfOccasion === "IndependenceDay") {
            image = event6
        }
        else if (occasion.typeOfOccasion === "SweetestDay") {
            image = event7
        }
        else if (occasion.typeOfOccasion == "Anniversary") {
            image = event14;
        }

        return image
    }

    function displayBudget(budget) {
        return budget == null ? "100" : budget
    }

    return (
        <Container fluid className="gifts-container" id="eventsDiv">
            <Container className="gifts">
                <EditGiftEventsPopUp
                    heading="Gift Events"
                    title="Еdit gift events"
                    occasions={occasions}
                    onEditedOccasions={(e) => props.onEditedOccasions(e)}
                    isCurrentUser={props.isCurrentUser}
                    connection={props.connection}
                />
                <Row>
                    <Col className="gift-events-container">
                        {
                            (occasions.length == 0)
                            &&
                            <EventItemModal
                                radioId=""
                                label="You have no gift events here"
                                image={eventsPlaceholder}
                                alt="You have no gift events here"
                                budget="Edit gift events"
                            />
                        }

                        {occasions.map((occ, index) => (
                            <EventItemModal
                                key={index}
                                radioId={occ.typeOfOccasion as string}
                                label={occ.typeOfOccasion as string}
                                image={getImage(occ)}
                                alt={occ.typeOfOccasion as string}
                                budget={displayBudget(occ.budget)}
                                onDelete={() => props.onDeleteOccasion(occ)}
                                onEdit={(budget, date) => props.onEditOccasionBudget(occ, budget, date)}
                                date={formatDate(occ.ocassionDate)}
                            />

                        ))}
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default OccasionComponent;