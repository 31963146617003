import { FunctionComponent, useRef} from "react";
import './BaseImageUpload.scss';

type ImageProps = {
    img?: string,
    alt: string,
    onClick?: any,
    imageHandler?: any,
    initials?: string,
    className?: string
  }

  const BaseImageUpload: FunctionComponent<ImageProps> = ({ img, alt, onClick, imageHandler, initials, className }) => {

    const inputFile = useRef<HTMLInputElement>(null);

    return (
        <div className="image-wrapper" onClick={onClick}>
            <label className="upload-button-label" >
                { img && <img src={img} alt={alt} className={className}></img> }
                <input ref={inputFile} id="imageInput" type="file" hidden  accept="image/*" multiple={false} onChange={(e) => imageHandler(e)} /> 
            </label>   
            { !img && <div className="initials-view">{!initials ? "?" : initials}</div> }    
        </div>
    )
}

export default BaseImageUpload;