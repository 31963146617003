import ManageConnections from '../manage-connections/ManageConnections'
import Subscription from '../subscription/Subscription'


const HomePage = () => {

    return(
        <div>
            <ManageConnections />
        </div>
    )
}

export default HomePage;