import React, { FunctionComponent, useState } from "react";
import { Container, Row, Col, Dropdown } from 'react-bootstrap';
import BaseButton from '../../base-components/BaseButton';
import HeaderModal from '../../modal/modal-components/HeaderModal';
import BaseFormInput from '../../base-components/BaseFormInput';
import Alert from '@material-ui/lab/Alert';

const ResetPasswordContainer = (props) => {

    const [password, setPassword] = useState("")
    const [newPassword, setNewPasswordInput] = useState('');
    const [confirmPassword, setConfirmPassword] = useState("")
    const [alertMessage, setAlertMessage] = useState('')

    function setNewPassword() {
        if (newPassword != confirmPassword) {
            setAlertMessage("Passwords don't match")
            return
        }

        if (props?.forgotPassword?.token) {
            console.log('TOKEN MOKEN ' + JSON.stringify(props.forgotPassword));
            fetch('api/Auth/ResetPassword',
                {
                    'method': 'POST',
                    'headers': {
                        'content-type': 'application/json',
                        'accept': 'application/json',
                    },
                    'body': JSON.stringify({
                        password: newPassword,
                        confirmPassword: confirmPassword,
                        email: props.forgotPassword.email,
                        token: props.forgotPassword.token
                    })
                })
                .then(response => {
                    if (response.status == 200) {
                        setAlertMessage('Passwords changed');
                    }
                    else {
                        setAlertMessage('Could not execute password change.')
                        return;
                    }
                })
        }

        else {
            fetch('api/Auth/ChangePassword',
                {
                    'method': 'PUT',
                    'headers': {
                        'content-type': 'application/json',
                        'accept': 'application/json',
                    },
                    'body': JSON.stringify({
                        currentPassword: password,
                        newPassword: confirmPassword,
                    })
                })
                .then(response => {
                    if (response.status == 200) {
                        setAlertMessage('Passwords changed');
                    }
                    else {
                        setAlertMessage('Could not execute password change.')
                        return;
                    }
                })
        }

        setTimeout(() => {
            props?.onHide()
        }, 2500)
    }

    return (
        <section className="set-password active">
            <HeaderModal
                heading="Set new password"
                span=""
                paragraph=""
            />

            <Container fluid>
                <Row>
                    <Col>
                        <div className="form-container">
                            {props?.forgotPassword?.token
                                ? null
                                : <BaseFormInput
                                    type="password"
                                    name="password"
                                    onChange={(e) => setPassword(e)}
                                    placeholder="Current Password" />}

                            <BaseFormInput
                                type="password"
                                name="confirm password"
                                onChange={(e) => setNewPasswordInput(e)}
                                placeholder="New Password" />
                            <BaseFormInput
                                type="password"
                                name="confirm password"
                                onChange={(e) => setConfirmPassword(e)}
                                placeholder="Confirm New Password" />
                        </div>
                        <BaseButton
                            onClick={setNewPassword}
                            title="Change Password"
                            icon=""
                            alt="">
                        </BaseButton>
                        {alertMessage &&
                            <Alert severity="info" color="info" >
                                {alertMessage}
                            </Alert>
                        }
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default ResetPasswordContainer;