import { AxiosError } from 'axios';

function stringifyErrorMessage(error) {
    let axiosError = error as AxiosError;
    if (axiosError) {
        if (axiosError.response?.data?.errors) {
            let errors = '';
            for (const property in axiosError.response?.data?.errors) {
                errors += `${property}: ${axiosError.response?.data?.errors[property]}`;
            }
            return errors;
        }
        else {
            return axiosError?.response?.data;
        }
    }
    else {
        return error.message;
    }
}

export { stringifyErrorMessage };