import React, { FunctionComponent } from "react";
import './DesignElement.scss';

type ElementProps = {
    imageSrc: string,
    alt: string
}

const DesignElement: FunctionComponent<ElementProps> = ({ imageSrc, alt }) => {
    return (
        <div className="element">
            <img src={imageSrc} alt={alt}></img>
        </div>
    )
}

export default DesignElement;