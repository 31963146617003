import { FunctionComponent, useEffect, useState, useContext } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './RecentRecommendations.scss'
import HeaderGroup from '../../components/base-components/HeaderGroup'
import ProductCard from '../../components/product-components/ProductCard'
import { Fade, Grow, Slide } from '@material-ui/core';
import refreshIcon from '../../assets/icons/refresh.svg'
import { IProduct, IUserOccasion } from '../../types/types-internal'
import { TrainingWizardStepPopUp } from '../modal/modal-training-wizard-step/TrainingWizardStepPopUp'
import BaseImage from '../../components/base-components/BaseImage'
import productsPlaceholder from '../../assets/svg/products-placeholder.svg';
import { IsNullOrEmpty, getHolidayName } from "../../utils/Utils";

type RecentRecommendationsProps = {
    products: IProduct[],
    occasion?: IUserOccasion,
    refreshProducts?: any,
    addProductToFavorites?: any,
    hideProduct?: any,
    user: { gender?: string, connectionId?: number },
    showTrainingModal: boolean,
    setShowTrainingModal: any,
    loadProducts: any,
    connectionName?: string,
    showFeedbackModal?: Function
}


const RecentRecommendations: FunctionComponent<RecentRecommendationsProps> = (props) => {
    const[clickCount, setClickCount] = useState(1);

    async function getNewRecommendations() {
        props.refreshProducts(props.occasion);
        setClickCount(clickCount + 1);
        if(props?.showFeedbackModal && clickCount > 5){
            let response = await fetch('api/Users/CheckForUserFeedback');
            if(response.status == 200){
                props.showFeedbackModal(true);
            }
            setClickCount(0);
        } 
    }

    function getOccasionName() {

        if (!IsNullOrEmpty(props.occasion)) {
            if (typeof props.occasion == 'string') {
                return getHolidayName(props.occasion + "") as string
            }
            else {
                return getHolidayName(props?.occasion?.typeOfOccasion)
            }
        }
        else {
            return ""
        }
    }

    return (
        <>
            <Container fluid className="products-container">
                <Fade in={true} timeout={800}>
                    <Container>
                        <HeaderGroup
                            onClick={() => getNewRecommendations()}
                            heading={getOccasionName() + " recommendations"}
                            buttonTitle="New Recommendations"
                            buttonIcon={refreshIcon}
                            showLikedButton = {true}
                            buttonAlt="New Recommendations" />
                        <Row>
                            {props.products.length >= 1 && props.products[0].externalProductId
                                ?
                                <Col className="recommended-products-container">
                                    {props.products.map((product) => (
                                        <ProductCard
                                            id={product.id}
                                            key = {product.id}
                                            ribbon={product.productCategory!.toString() as string}
                                            img={product.productImages[1] != undefined ? product.productImages[1].imageUrl : product.productImages[0].imageUrl}
                                            img2={product.productImages[0] != undefined ? product.productImages[0].imageUrl : "/static/media/wise-prezzie-logo.862d5236.svg"}
                                            alt={product.title!}
                                            title={product.title!}
                                            subtitle={product.subtitle!}
                                            brand={product.brand.toString() as string}
                                            price={product.price!}
                                            productUrl={product.productLink}
                                            product={product}
                                            addProductToFavorites={(likeStatus) => props.addProductToFavorites(product, likeStatus)}
                                            hideProduct={() => props.hideProduct(product)}
                                        />
                                    ))}
                                </Col>
                                :
                                <Col>
                                    <div className="placeholder-image">
                                        <BaseImage
                                            img={productsPlaceholder}
                                        />
                                        <p className="pt-3">No recommendations</p>
                                        <p>Fetch new recommendations</p>
                                    </div>
                                </Col>
                            }
                            <TrainingWizardStepPopUp
                                show={props.showTrainingModal}
                                onHide={() => props.setShowTrainingModal(false)}
                                connectionId={props?.user?.connectionId}
                                gender={props?.user?.gender}
                                loadProducts={() => props.loadProducts()}
                                connectionName={props.connectionName}
                                occasion={props.occasion}
                            />
                        </Row>
                    </Container>
                </Fade>
            </Container>
        </>
    )
}

export default RecentRecommendations;
