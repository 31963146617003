import React, { useState } from "react";
import { Link } from 'react-router-dom';
import './CategoriesNavbar.scss';
import category from '../../assets/icons/category.svg';
import { Container } from "react-bootstrap";

const CategoriesNavbar = () => {
    const [isActive, setActive] = useState(false);

    const handleToggle = () => {
        setActive(!isActive);
    };
    return (
        <Container fluid className="nav-container">
            <Container>
                <nav className={`categories-navbar ${isActive ? "open-list" : ""}`}>
                    <Link to={'/'} className="logo">
                        <img src={category} alt="Wise Prezzie Logo" />
                    </Link>
                    <div className="menu-icon" onClick={handleToggle}>Gift categories<span className="plus"></span></div>
                    <div className="nav-list">
                        <Link to={'/new-gifts'} className="nav-item">Clothing</Link>
                        <Link to={'/new-gifts'} className="nav-item">Shoes</Link>
                        <Link to={'/new-gifts'} className="nav-item">Bags</Link>
                        <Link to={'/new-gifts'} className="nav-item">Accessories</Link>
                        <Link to={'/new-gifts'} className="nav-item">Jewelry</Link>
                        <Link to={'/new-gifts'} className="nav-item">HouseHold</Link>
                        <Link to={'/new-gifts'} className="nav-item">Music</Link>
                        <Link to={'/new-gifts'} className="nav-item">Movies</Link>
                        <Link to={'/new-gifts'} className="nav-item">Shows</Link>
                        <Link to={'/new-gifts'} className="nav-item">Sports</Link>
                        <Link to={'/new-gifts'} className="nav-item">Travel</Link>
                    </div>
                </nav>
            </Container>
        </Container>
    )
}

export default CategoriesNavbar;